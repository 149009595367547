import { FC, ReactElement, useContext } from 'react'
import { Link } from 'react-router-dom'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTicket, faCreditCard, faMobileScreen, faGear, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { faIdCard } from '@fortawesome/free-regular-svg-icons'
import UserContext from '../contexts/user'

type LinksProps = {
    url: string
    name: string
    icon: IconProp
}

const MenuLinkTemplate: FC<LinksProps> = ({ url, name, icon }) => {
    return (
        <li>
            <Link className="dropdown-item" to={url}>
                <FontAwesomeIcon icon={icon} fixedWidth={true} />
                <span className="px-2 flex-grow-1">{name}</span>
            </Link>
        </li>
    )
}

const LoginLinks: FC = (): ReactElement => {
    const { user } = useContext(UserContext)

    if (user) {
        // Wersja dla zalogowanych
        return (
            <div className="header-links">
                <Link to="/tickets/buy" className="btn btn-primary">
                    Kup bilet
                </Link>
                <div className="dropdown">
                    <button
                        className="btn btn-secondary dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false">
                        Moje konto
                    </button>
                    <ul className="dropdown-menu dropdown-menu-end">
                        <MenuLinkTemplate url="/tickets" name="Moje bilety" icon={faTicket} />
                        <li>
                            <hr className="dropdown-divider" />
                        </li>
                        <MenuLinkTemplate url="/your-account/passengers" name="Dane pasażerów" icon={faIdCard} />
                        <MenuLinkTemplate url="/your-account/cards" name="Karty płatnicze" icon={faCreditCard} />
                        <MenuLinkTemplate url="/your-account/devices" name="Urządzenia mobilne" icon={faMobileScreen} />
                        <MenuLinkTemplate url="/your-account/settings" name="Ustawienia konta" icon={faGear} />
                        <li>
                            <hr className="dropdown-divider" />
                        </li>
                        <MenuLinkTemplate url="/logout" name="Wyloguj się" icon={faSignOutAlt} />
                    </ul>
                </div>
            </div>
        )
    } else {
        // Wersja dla niezalogowanych
        return (
            <div className="header-links">
                <Link to="/tickets/buy" className="btn btn-primary">
                    Kup bilet
                </Link>
                <Link to="/sign-in" className="btn btn-secondary">
                    Zaloguj się
                </Link>
            </div>
        )
    }
}

export default LoginLinks
