import { FC, ReactElement, Fragment, cloneElement } from 'react'
import {
    LoaderFunction,
    ActionFunction,
    Link,
    Outlet,
    Navigate,
    useLocation,
    useLoaderData,
    useActionData,
    useRouteLoaderData,
    useParams,
    useNavigate,
    redirect,
} from 'react-router-dom'
import api, { ApiValidationError } from '../../lib/Api'
import Form, { useFormValues, useFormErrors, FormValues } from '../../lib/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import Modal from 'react-bootstrap/Modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

export interface Passenger {
    id?: number
    customName: string
    firstName: string
    lastName: string
    address1: string
    address2?: string
    postalCode: string
    city: string
    phoneNumber?: string
    companyName?: string
    companyTaxIdentifier?: string
    ticketPriceReliefId?: number
}

export const passengersLoader: LoaderFunction = async () => {
    return await api.get('/api/passengers').then(r => r.data.passengers)
}

export const passengerSaveAction: ActionFunction = async ({ request, params: { passengerId } }) => {
    const formData = await request.formData()
    const passenger = Object.fromEntries(Array.from(formData).filter(([k, v]) => !!v)) as object as Passenger

    try {
        // Będziemy tworzyć nowy rekord lub aktualizować istniejący
        type ReturnType = {
            success: boolean
            id?: number
        }
        const action = (passenger: Passenger) =>
            passengerId
                ? api.put<ReturnType>(`/api/passengers/${passengerId}`, passenger)
                : api.post<ReturnType>('/api/passengers', passenger)

        // Przesyłamy dane pasażera
        const { data } = await action(passenger)

        if (!data.success) {
            throw new Error()
        }

        // Zwracamy informację o pomyślnym zapisie
        return {
            success: true,
            passengerId: passengerId ?? data.id?.toString(),
        }
    } catch (error) {
        if (error instanceof ApiValidationError) {
            return {
                [error.fieldName]: 'Nieprawidłowa wartość',
                message: 'Popraw dane w formularzu zaznaczone na czerwono',
            }
        }

        return {
            message: 'Nie udało się zapisać danych',
        }
    }
}

const PassengerForm: FC<{ passenger: Passenger | null }> = ({ passenger }): ReactElement => {
    const navigate = useNavigate()
    const location = useLocation()
    const { success, passengerId } = (useActionData() as { success?: boolean; passengerId?: string }) ?? {}

    const handleClose = () => navigate(-1)

    const [errors, setErrors] = useFormErrors()
    const [values, onChange] = useFormValues(
        passenger
            ? (passenger as unknown as FormValues)
            : {
                  customName: '',
                  firstName: '',
                  lastName: '',
                  address1: '',
                  address2: '',
                  postalCode: '',
                  city: '',
                  phoneNumber: '',
                  companyName: '',
                  companyTaxIdentifier: '',
                  ticketPriceReliefId: '1',
              }
    )

    // Jeśli zapis się udał, to zamykamy okno
    if (success) {
        const successUrl = location.state?.successUrl?.replace(/:passengerId/g, passengerId) ?? '..'
        return <Navigate to={successUrl} replace={true} />
    }

    return (
        <Modal show={true} onHide={handleClose} backdrop="static" centered>
            <Modal.Header closeButton>
                <Modal.Title>{passenger ? 'Edycja pasażera' : 'Dodawanie pasażera'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {errors.message && (
                    <Alert variant="danger" className="text-center">
                        {errors.message}
                    </Alert>
                )}
                <Form method="post" dispatchError={setErrors}>
                    <div className="row g-3">
                        <div className="col-7">
                            <Form.Control
                                id="customName"
                                name="customName"
                                autoComplete="nickname"
                                placeholder="Nazwa własna *"
                                value={values.customName as string}
                                onChange={onChange}
                                required
                                autoFocus
                                isInvalid={!!errors.customName}
                            />
                        </div>
                        <div className="col-5">
                            <Form.Control
                                id="phoneNumber"
                                name="phoneNumber"
                                type="tel"
                                autoComplete="tel"
                                placeholder="Numer telefonu"
                                value={values.phoneNumber as string}
                                onChange={onChange}
                                isInvalid={!!errors.phoneNumber}
                            />
                        </div>

                        <div className="col-5">
                            <Form.Control
                                id="firstName"
                                name="firstName"
                                autoComplete="given-name"
                                placeholder="Imię *"
                                value={values.firstName as string}
                                onChange={onChange}
                                required
                                isInvalid={!!errors.firstName}
                            />
                        </div>
                        <div className="col-7">
                            <Form.Control
                                id="lastName"
                                name="lastName"
                                autoComplete="family-name"
                                placeholder="Nazwisko *"
                                value={values.lastName as string}
                                onChange={onChange}
                                required
                                isInvalid={!!errors.lastName}
                            />
                        </div>

                        <div className="col-4">
                            <Form.Control
                                id="postalCode"
                                name="postalCode"
                                autoComplete="postal-code"
                                placeholder="Kod pocztowy *"
                                value={values.postalCode as string}
                                onChange={onChange}
                                required
                                isInvalid={!!errors.postalCode}
                            />
                        </div>
                        <div className="col-8">
                            <Form.Control
                                id="city"
                                name="city"
                                autoComplete="address-level2"
                                placeholder="Miasto *"
                                value={values.city as string}
                                onChange={onChange}
                                required
                                isInvalid={!!errors.city}
                            />
                        </div>

                        <div className="col-12">
                            <Form.Control
                                id="address1"
                                name="address1"
                                autoComplete="address-line1"
                                placeholder="Adres 1 *"
                                value={values.address1 as string}
                                onChange={onChange}
                                required
                                isInvalid={!!errors.address1}
                            />
                        </div>

                        <div className="col-12">
                            <Form.Control
                                id="address2"
                                name="address2"
                                autoComplete="address-line2"
                                placeholder="Adres 2"
                                value={values.address2 as string}
                                onChange={onChange}
                                isInvalid={!!errors.address2}
                            />
                        </div>

                        <div className="col-8">
                            <Form.Control
                                id="companyName"
                                name="companyName"
                                autoComplete="organization"
                                placeholder="Firma"
                                value={values.companyName as string}
                                onChange={onChange}
                                isInvalid={!!errors.companyName}
                            />
                        </div>

                        <div className="col-4">
                            <Form.Control
                                id="companyTaxIdentifier"
                                name="companyTaxIdentifier"
                                placeholder="NIP"
                                value={values.companyTaxIdentifier as string}
                                onChange={onChange}
                                isInvalid={!!errors.companyTaxIdentifier}
                            />
                        </div>

                        {/* @TODO: Zrobić wybór ulgi */}

                        <div className="col-12 text-end">
                            <Button variant="secondary" type="button" onClick={handleClose} className="me-3">
                                Anuluj
                            </Button>
                            <Button variant="primary" type="submit">
                                Zapisz
                            </Button>
                        </div>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export const PassengerAddForm: FC = (): ReactElement => {
    return <PassengerForm passenger={null} />
}

export const PassengerEditForm: FC = (): ReactElement => {
    const passengers = useRouteLoaderData('passengers') as Passenger[]
    const { passengerId } = useParams()
    const passenger = passengers.find(passenger => passenger.id === parseInt(passengerId as string)) ?? null

    if (!passenger) {
        // Jeśli nie znaleziono pasażera, to otwieramy formularz dodawania nowego
        return <Navigate to="../add" replace={true} />
    }

    return <PassengerForm passenger={passenger} />
}

export const passengerDeleteAction: ActionFunction = async ({ request, params: { passengerId } }) => {
    if (request.method !== 'DELETE') {
        // Zamykamy okno
        return redirect('..')
    }

    try {
        // Kasujemy konto użytkownika
        const {
            data: { success },
        } = await api.delete<{ success: boolean }>(`/api/passengers/${passengerId}`)

        if (!success) {
            throw new Error()
        }

        // Zamykamy okno
        return redirect('..')
    } catch (error) {
        return {
            message: 'Nie udało się skasować danych. Spróbuj ponownie za chwilę.',
        }
    }
}

export const PassengersDeleteForm: FC = (): ReactElement => {
    const navigate = useNavigate()
    const handleClose = () => navigate('..', { replace: true })

    const [errors, setErrors] = useFormErrors()

    return (
        <Modal show={true} onHide={handleClose} size="sm" centered>
            <Modal.Header closeButton>
                <Modal.Title>Usuwanie pasażera</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {errors.message && (
                    <Alert variant="danger" className="text-center">
                        {errors.message}
                    </Alert>
                )}
                <Form method="delete" dispatchError={setErrors}>
                    <p className="small">Czy na pewno chcesz usunąć dane pasażera?</p>

                    <div className="d-block text-end">
                        <Button variant="secondary" type="button" onClick={handleClose}>
                            Anuluj
                        </Button>{' '}
                        <Button variant="primary" type="submit">
                            Usuń
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export const PassengerTextView: FC<{ passenger: Passenger; separator: ReactElement }> = ({
    passenger,
    separator,
}): ReactElement => {
    // Budujemy kolejne linie tekstu do wyświetlenia
    const infoText: string[] = []
    let taxId = passenger.companyTaxIdentifier ? `NIP: ${passenger.companyTaxIdentifier}` : null
    if (passenger.companyName) taxId = `(${taxId})`
    infoText.push([passenger.companyName, taxId].filter(Boolean).join(' '))
    infoText.push([passenger.firstName, passenger.lastName].filter(Boolean).join(' '))
    infoText.push(passenger.address1 ?? '')
    infoText.push(passenger.address2 ?? '')
    infoText.push([passenger.postalCode, passenger.city].filter(Boolean).join(' '))

    // Zamieniamy linie tekstu na komponenty React/HTML
    const info = infoText
        .map((text, idx) => (text ? <Fragment key={idx}>{text}</Fragment> : null))
        .reduce((result, item) => {
            if (!item) return result
            if (!result.length) return [item]
            return [...result, cloneElement(separator, { key: `sep-${item.key}` }), item]
        }, [] as ReactElement[])

    return <Fragment>{info}</Fragment>
}

const PassengerView: FC<{ passenger: Passenger }> = ({ passenger }): ReactElement => {
    return (
        <Card className="h-100">
            <Card.Header>{passenger.customName}</Card.Header>
            <Card.Body>
                <p className="card-text">
                    <PassengerTextView passenger={passenger} separator={<br />} />
                </p>
            </Card.Body>
            <Card.Footer className="bg-transparent border-top-0 text-center">
                <Link to={`./edit/${passenger.id}`} className="small card-link text-decoration-none">
                    Edytuj
                </Link>
                <Link to={`./delete/${passenger.id}`} className="small card-link text-decoration-none">
                    Usuń
                </Link>
            </Card.Footer>
        </Card>
    )
}

const PassengersPage: FC = (): ReactElement => {
    const passengers = useLoaderData() as Passenger[]

    return (
        <>
            <h1 className="h4 mb-3">Dane pasażerów</h1>

            <Row xs={2} sm={1} md={2} className="g-4">
                {passengers.map(passenger => (
                    <Col key={passenger.id}>
                        <PassengerView passenger={passenger} />
                    </Col>
                ))}
                <Col>
                    <Card className="h-100" as="span">
                        <Card.Body
                            className="d-flex flex-column align-items-center justify-content-center text-center text-muted py-4"
                            as="span">
                            <FontAwesomeIcon
                                icon={faPlus}
                                className="d-block mx-auto display-3 mb-3"
                                style={{ opacity: 0.2 }}
                            />
                            <Link to="./add" className="stretched-link text-decoration-none text-muted">
                                Dodaj nowego pasażera
                            </Link>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Outlet />
        </>
    )
}

export default PassengersPage
