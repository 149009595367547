import { FC, ReactElement, PropsWithChildren } from 'react'
import Card from 'react-bootstrap/Card'
import { TicketInfo, isActivatedTicket } from '../lib/Interfaces'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBookmark } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import 'moment/locale/pl'

interface Props {
    ticket: TicketInfo
}

const Ticket: FC<PropsWithChildren<Props>> = ({ ticket, children }: PropsWithChildren<Props>): ReactElement => {
    const formatter = new Intl.NumberFormat('pl-PL', {
        style: 'currency',
        currency: 'PLN',
    })

    let type = 'bought' as 'active' | 'bought' | 'recently-expired' | 'expired'
    let controlDetails = null
    if (isActivatedTicket(ticket)) {
        let periodDescription = null
        const activeFrom = moment(ticket.activeFrom)
        const activeTo = moment(ticket.activeTo)
        const expirationTime = moment(ticket.expirationTime)
        const currentTime = moment()

        if (activeFrom.isSameOrBefore(currentTime) && activeTo.isSameOrAfter(currentTime)) {
            type = 'active'
        } else if (activeTo.isBefore(currentTime) && expirationTime.isSameOrAfter(currentTime)) {
            // Bilety, które straciły ważność, ale jeszcze przez krótki czas pokazujemy
            // w grupie Aktywowane
            type = 'recently-expired'
        } else if (activeTo.isBefore(currentTime)) {
            type = 'expired'
        }

        if (ticket.activationTimeSelect === 'Month') {
            periodDescription = 'Ważność biletu: ' + activeFrom.format('MMMM YYYY')
        } else if (moment(activeFrom).startOf('day').isSame(activeFrom)) {
            periodDescription = `Ważny od ${activeFrom.format('DD.MM')} do ${activeTo.format('DD.MM.YYYY')}`
        } else if (moment(activeFrom).startOf('day').isSame(moment(activeTo).startOf('day'))) {
            periodDescription = `Ważny ${activeFrom.format('DD.MM.YYYY HH:mm')}-${activeTo.format('HH:mm')}`
        } else {
            periodDescription = `Ważny od ${activeFrom.format('DD.MM.YYYY HH:mm:ss')}`
        }

        controlDetails = (
            <div className="text-muted small">
                {periodDescription}
                <br />
                {ticket.controlDetails}
            </div>
        )
    }

    const cssClasses = ['h-100', 'ticket-view']
    cssClasses.push('ticket-type-' + ticket.type.toLowerCase())
    cssClasses.push('ticket-' + type)

    return (
        <Card className={cssClasses.join(' ')} as="span">
            <Card.Body>
                <Card.Title>
                    {!children && (
                        <FontAwesomeIcon icon={faBookmark} className={`ticket-ribbon-${type} float-end ms-2`} />
                    )}
                    {ticket.name}
                </Card.Title>
                <Card.Subtitle>{ticket.discountDetails}</Card.Subtitle>
                <Card.Text>{ticket.zones}</Card.Text>
                {controlDetails}
                {type === 'recently-expired' && <div className="recently-expired-msg">Bilet po limicie czasowym</div>}
            </Card.Body>
            <Card.Footer>
                {formatter.format(ticket.price / 100.0)} {children}
            </Card.Footer>
        </Card>
    )
}

export default Ticket
