import { FC, ReactElement, PropsWithChildren } from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as Tile1 } from '../assets/images/homepage-tiles/tile1.svg'
import { ReactComponent as Tile2 } from '../assets/images/homepage-tiles/tile2.svg'
import { ReactComponent as Tile3 } from '../assets/images/homepage-tiles/tile3.svg'
import { ReactComponent as Tile4 } from '../assets/images/homepage-tiles/tile4.svg'
import { ReactComponent as GooglePlayBadge } from '../assets/images/google-play-badge.svg'
import { ReactComponent as AppStoreBadge } from '../assets/images/app-store-badge.svg'

const HomePage: FC = (): ReactElement => {
    return (
        <>
            <div id="welcome">
                <div className="container container-sm">
                    <h1>
                        Podróżuj wygodnie
                        <br /> i ekologicznie już teraz
                    </h1>
                    <h2 className="h3">Przesiądź się na e-bilet!</h2>
                    <a href="#ebilet" className="btn btn-primary">
                        Czytaj dalej
                    </a>
                </div>
            </div>

            <div id="ebilet" className="container container-sm">
                <h2>Nowe e-bilety w Rzeszowie</h2>
                <p>
                    Rzeszowski Transport Miejski wprowadził nowe możliwości wygodnego podróżowania po Rzeszowie.
                    <br />
                    Kupuj bilety online w aplikacji mobilnej e-Bilet Rzeszów bądź przypisuj je do swojej karty
                    płatniczej.
                </p>
                <ul>
                    <li>
                        <Tile1 />
                        <span>Kupujesz bilety online, gdziekolwiek jesteś</span>
                    </li>
                    <li>
                        <Tile2 />
                        <span>Wiesz, ile wydałeś już&nbsp;na przejazdy</span>
                    </li>
                    <li>
                        <Tile3 />
                        <span>Chronisz środowisko, wybierając e-bilet</span>
                    </li>
                    <li>
                        <Tile4 />
                        <span>Możesz kupić bilet&nbsp;rodzinie</span>
                    </li>
                </ul>
            </div>

            <div id="homepage-buy-ticket">
                <div className="container container-sm">
                    <h2>Ruszasz w drogę?</h2>
                    <p>
                        Kup bilet online! Przez internet kupisz bilety okresowe oraz imienne. Szybko, wygodnie
                        i&nbsp;bezpiecznie. Miłej podróży!
                    </p>
                    <Link to="/tickets/buy" className="btn btn-primary">
                        Kup bilet
                    </Link>
                </div>
            </div>

            <div id="homepage-mobile-app" className="container container-sm">
                <div className="wrap">
                    <h2>Aplikacja e-Bilet Rzeszów</h2>
                    <ul>
                        <li>Zainstaluj aplikację e-Bilet Rzeszów. Załóż konto lub zarejestruj się.</li>
                        <li>Płać za e-bilety kartą lub BLIKiem. Szybko i bezpiecznie.</li>
                        <li>e-bilet aktywuj po wejściu, a podczas kontroli pokaż kod QR</li>
                    </ul>
                    <p className="h6">Pobierz aplikację i ruszaj w drogę.</p>
                    <div className="links">
                        <a
                            href="https://play.google.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                            title="Pobierz z Google Play"
                            className="col text-center">
                            <GooglePlayBadge preserveAspectRatio="xMaxYMid meet" height="60" />
                        </a>{' '}
                        <a
                            href="https://www.apple.com/pl/app-store/"
                            target="_blank"
                            rel="noopener noreferrer"
                            title="Pobierz z App Store"
                            className="col text-center">
                            <AppStoreBadge preserveAspectRatio="xMinYMid meet" height="60" />
                        </a>
                    </div>
                </div>
            </div>

            <div id="homepage-card-ticket" className="container container-sm">
                <div className="wrap">
                    <h2>e-bilet na karcie bankowej</h2>
                    <p>Gdy już jesteś w autobusie, możesz kupić kartą dowolny bilet jednoprzejazdowy lub minutowy.</p>
                    <p>Biletomat przypisze bilet do twojej karty. Pamiętaj, że jest już aktywowany. Miłej podróży!</p>
                    <p>
                        W razie kontroli zbliż kartę do czytnika kontrolera.
                        <br />
                        Dziękujemy, że kupujesz bilety!
                    </p>
                </div>
            </div>

            <div id="homepage-faq" className="container container-sm">
                <h2>Najczęstsze pytania i odpowiedzi</h2>
                <div className="accordion" id="accordionFAQ">
                    <FaqQuestion id="faqQuestion1" question="Pobierz aplikację i ruszaj w drogę.">
                        <p>
                            Aplikacja mobilna jest nośnikiem biletu podpiętym do konta w sklepie "e-bilet". Każda
                            instancja aplikacji stanowi odrębny nośnik. W związku z tym bilety zakupione w jednej
                            aplikacji mobilnej nie są widoczne w innej, mimo zalogowania się do tego samego konta w
                            sklepie.
                        </p>
                        <p>
                            Aplikacja dostępna jest na system Android (wersje 9-13) i iOS (wersje 15-16.2). Aplikacje
                            można pobrać ze sklepów{' '}
                            <a
                                href="https://www.apple.com/pl/app-store/"
                                target="_blank"
                                rel="noopener noreferrer"
                                title="App Store">
                                Apple
                            </a>{' '}
                            i{' '}
                            <a
                                href="https://play.google.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                                title="Google Play">
                                Google
                            </a>
                            .
                        </p>
                        <p>Aby rozpocząć korzystanie z aplikacji mobilnej załóż konto w sklepie "e-bilet".</p>
                        <video controls poster="/filmy/01_zalozenie_konta.png">
                            <source src="/filmy/01_zalozenie_konta_03.mp4" type="video/mp4" />
                        </video>
                    </FaqQuestion>
                    <FaqQuestion id="faqQuestion2" question="Jakie są nośniki e-biletów?">
                        <p>
                            Nośnikami e-biletów są aplikacja mobilna, karta płatnicza oraz jej zwirtualizowane
                            odpowiedniki.
                        </p>
                        <p>
                            Należy pamiętać, że każda instancja aplikacji stanowi odrębny nośnik. W związku z tym bilety
                            zakupione w jednej aplikacji mobilnej nie są widoczne w innej, mimo zalogowania się do tego
                            samego konta w sklepie.
                        </p>
                        <p>
                            Również karta płatnicza i jej zwirtualizowany odpowiednik to dwa różne nośniki. Jeżeli
                            zakupisz bilet na kartę fizyczną, to nie będzie on widoczny na karcie zwirtualizowanej np. w
                            portfelu Google i odwrotnie.
                        </p>
                        <video controls poster="/filmy/03_nosnik_biletu.png">
                            <source src="/filmy/03_nosnik_biletu_02.mp4" type="video/mp4" />
                        </video>
                    </FaqQuestion>
                    <FaqQuestion id="faqQuestion3" question="Jak kupić e-bilet?">
                        <p>
                            Aby zakupić e-bilet w portalu lub aplikacji mobilnej musisz zdefiniować co najmniej jednego
                            pasażera. W sklepie internetowym "e-bilet" dostępne są bilety krótkookresowe i miesięczne,
                            imienne, od 5-dniowych do 180-dniowych. Zakupiony za pośrednictwem portalu bilet można
                            przypisać do zdefiniowanej karty płatniczej lub aplikacji mobilnej. Natomiast w aplikacji
                            mobilnej dostępne są bilety czasowe: 60-minutowe jednoprzejazdowe, 90-minutowe, 24-godzinne,
                            bilety krótkoterminowe, imienne 5-dniowe oraz miesięczne: od 30-dniowych do 180-dniowych.
                            Dokonanie zakupu w aplikacji sprawia, że bilet jest automatycznie do niej przypisany. Bilety
                            kodowane na kartę płatniczą można zakupić także w biletomatach lub jednorazowe w kasowniku.
                            Wybierając zakup e-biletów przez portal, użytkownicy mogą dokonać płatności za pomocą
                            przelewu, karty bankowej, BLIKa oraz Google Pay. Natomiast w aplikacji mobilnej dostępne
                            formy płatności to: Google Pay, Apple Pay oraz BLIK.
                        </p>
                        <video controls poster="/filmy/04_zakup_biletu.png">
                            <source src="/filmy/04_zakup_biletu_02.mp4" type="video/mp4" />
                        </video>
                    </FaqQuestion>
                    <FaqQuestion id="faqQuestion4" question="Jak skasować e-bilet?">
                        <p>
                            Bilety okresowe imienne od 5-dniowych do 180-dniowych nie wymagają kasowania. Stają się one
                            automatycznie aktywne z wybraną datą aktywacji.
                        </p>
                        <p>
                            Bilety 60-minutowe jednoprzejazdowe, 90-minutowe i 24-godzinne zakupione w aplikacji
                            mobilnej wymagają aktywacji. W przypadku aktywacji biletu 60-minutowego jednoprzejazdowego
                            konieczne jest podanie numeru bocznego pojazdu.
                        </p>
                        <p>
                            Bilet 60-minutowy jednoprzejazdowy zakupiony w kasowniku jest kasowany automatycznie w
                            momencie zakupu.
                        </p>
                        <video controls poster="/filmy/05_kasowanie_biletu.png">
                            <source src="/filmy/05_kasowanie_biletu_02.mp4" type="video/mp4" />
                        </video>
                    </FaqQuestion>
                    <FaqQuestion id="faqQuestion5" question="Jak wygląda kontrola w przypadku posiadania e-biletu?">
                        <p>
                            Kontrola biletu przypisanego do karty bankowej polega na przyłożeniu karty z przypisanym
                            biletem do urządzenia kontrolerskiego.
                        </p>
                        <p>
                            Kontrola biletu w aplikacji mobilnej polega na prezentacji kodu QR biletu w aplikacji. Kod
                            jest sczytywany przez urządzenie kontrolerskie.
                        </p>
                        <p>
                            Pasażer może zostać poproszony o okazanie dokumentu tożsamości. Ponadto, jeżeli pasażer
                            korzysta z ulgi, ma obowiązek przedstawienia dokumentów upoważniających do korzystania z
                            niej.
                        </p>
                        <video controls poster="/filmy/06_kontrola_biletu.png">
                            <source src="/filmy/06_kontrola_biletu_01.mp4" type="video/mp4" />
                        </video>
                    </FaqQuestion>
                    <FaqQuestion id="faqQuestion6" question="Jak przypisać pasażera do e-biletu?">
                        <p>
                            W portalu, w zakładce Dane pasażerów, należy zdefiniować przynajmniej jednego pasażera, co
                            umożliwi zakup biletów. Pola wymagane to Nazwa własna, Imię, Nazwisko, Kod pocztowy, Miasto
                            i Adres.
                        </p>
                        <p>
                            Tak zdefiniowany pasażer automatycznie parowany jest z kartą płatniczą podczas jej
                            dodawania.
                        </p>
                        <p>
                            Przypisanie instancji aplikacji mobilnej następuje w momencie zakupu biletu. Dla ułatwienia
                            obsługi można edytować nazwę aplikacji mobilnej, która rejestrowana jest domyślnie jako
                            nazwa producenta i model telefonu, na którym jest instalowana. Nie ma jednoznacznego
                            powiązania pomiędzy pasażerem i aplikacją mobilną, ponieważ w jednej aplikacji mobilnej mogą
                            być przypisane bilety dla różnych osób, np. rodzica z dzieckiem lub przyjaciół podróżujących
                            wspólnie.
                        </p>
                        <video controls poster="/filmy/02_definiowanie_danych.png">
                            <source src="/filmy/02_definiowanie_danych_02.mp4" type="video/mp4" />
                        </video>
                    </FaqQuestion>
                </div>
            </div>
        </>
    )
}

export default HomePage

interface FaqQuestionProps {
    id: string
    question: string
}

const FaqQuestion: FC<PropsWithChildren<FaqQuestionProps>> = ({ id, question, children }): ReactElement => {
    return (
        <div className="accordion-item">
            <h2 className="accordion-header">
                <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={'#' + id}
                    aria-expanded="false"
                    aria-controls={id}>
                    {question}
                </button>
            </h2>
            <div id={id} className="accordion-collapse collapse" data-bs-parent="#accordionFAQ">
                <div className="accordion-body">{children}</div>
            </div>
        </div>
    )
}
