import { FC, ReactElement } from 'react'
import { useLocation, Link } from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import Form, { useFormValues, useFormErrors } from '../lib/Form'
import { PasswordField } from '../components/ChangePasswordFields'

const LoginForm: FC = (): ReactElement => {
    const [errors, setErrors] = useFormErrors()
    const [values, onChange] = useFormValues({
        email: '',
        password: '',
    })

    // Adres powrotu po zalogowaniu
    const location = useLocation()
    const returnUrl = new URLSearchParams(location.search).get('returnUrl')
    const returnParam = returnUrl ? '?' + new URLSearchParams({ returnUrl: returnUrl }).toString() : ''

    return (
        <>
            <h1 className="h4 mb-3">Logowanie</h1>
            <Form className="sign-in" action={'/sign-in' + returnParam} method="post" dispatchError={setErrors}>
                <Form.Group className="form-group" controlId="email">
                    <Form.Label>Adres email</Form.Label>
                    <Form.Control
                        type="text"
                        name="email"
                        autoComplete="username"
                        value={values.email}
                        onChange={onChange}
                        required
                        autoFocus
                        isInvalid={!!errors.message}
                    />
                </Form.Group>

                <Form.Group className="form-group" controlId="password">
                    <Form.Label>Hasło</Form.Label>
                    <small className="text-muted mt-1 float-end">
                        <Link className="text-decoration-none" to="/password-reset">
                            Przypomnij hasło
                        </Link>
                    </small>
                    <PasswordField
                        name="password"
                        value={values.password as string}
                        error={errors.message}
                        autoComplete="current-password"
                        onChange={onChange}
                    />
                </Form.Group>

                <div className="d-grid">
                    <Button variant="primary" type="submit">
                        Zaloguj
                    </Button>
                </div>
            </Form>
        </>
    )
}

export default LoginForm
