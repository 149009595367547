import { FC, ReactElement } from 'react'
import {
    ActionFunction,
    Link,
    Navigate,
    redirect,
    useParams,
    useLoaderData,
    useActionData,
    useNavigate,
    useLocation,
} from 'react-router-dom'
import Form, { useFormValues, useFormErrors } from '../../lib/Form'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import api, { ApiValidationError } from '../../lib/Api'
import AuthProvider, { TokenResponse, CLIENT_ID } from '../../lib/AuthProvider'
import VeryficationCodeForm from '../../components/VeryficationCodeForm'
import ChangePasswordFields from '../../components/ChangePasswordFields'

enum FormSteps {
    SendEmail = 1,
    VeryficationCode = 2,
    SetPassword = 3,
    Summary = 4,
}

export const passwordResetAction: ActionFunction = async ({ params, request }) => {
    const formData = await request.formData()

    // Pierwszy krok - generujemy nowy kod weryfikacyjny i przesyłamy
    // na wskazany adres e-mail
    if (!params.verificationId) {
        try {
            const email = formData.get('email') as string
            const {
                data: { verificationId },
            } = await api.post<{ verificationId: string }>('/api/verifications/password-reset', {
                email,
            })

            AuthProvider.setVeryficationEmail(verificationId, email)

            return redirect(`/password-reset/${verificationId}`)
        } catch (error) {
            return {
                step: FormSteps.SendEmail,
                message:
                    error instanceof ApiValidationError
                        ? 'Wprowadzono nieprawidłowy adres e-mail.'
                        : 'Wystąpił problem podczas odzyskiwania hasła. Spróbuj ponownie za chwilę.',
            }
        }
    }

    // Drugi krok - przekazujemy wprowadzony kod weryfikacyjny w adresie URL
    // i przechodzimy do kolejnego kroku
    if (!params.verificationCode) {
        const verificationId = params.verificationId
        const verificationCode = formData.get('verification_code') as string
        return redirect(`/password-reset/${verificationId}/${verificationCode}`)
    }

    // Trzeci krok - zapis nowego hasła
    try {
        // Walidacja po stronie klienta jeszcze przed wysłaniem danych
        if (formData.get('password_confirm') !== formData.get('password')) {
            return {
                step: FormSteps.SetPassword,
                password_confirm: 'Wprowadzone hasła muszą być takie same.',
            }
        }

        // Przesyłamy nowe hasło na serwer
        const {
            data: { accessToken, refreshToken },
        } = await api.put<TokenResponse>(
            '/api/users/password',
            {
                verificationId: params.verificationId,
                verificationCode: params.verificationCode,
                password: formData.get('password'),
                clientId: CLIENT_ID,
            },
            {
                headers: {
                    // Nie chcemy przekazywać tokenu JWT ponieważ wtedy jest procedura
                    // zmiany hasła dla zalogowanego użytkownika, czyli z podaniem
                    // starego hasła
                    Authorization: null,
                },
            }
        )

        // Aktualizujemy tokeny autoryzacyjne
        AuthProvider.setTokens(accessToken, refreshToken)

        // Wyświetlamy komunikat o sukcesie
        return {
            step: FormSteps.Summary,
        }
    } catch (error) {
        if (error instanceof ApiValidationError) {
            if (error.fieldName === 'verificationCode') {
                if (error.details === 'Verification id not found or expired') {
                    return {
                        step: FormSteps.SendEmail, // wracamy do kroku wpisywania adresu e-mail
                        message: 'Przesłany kod stracił swoją ważność. Należy wygenerować nowy.',
                    }
                } else {
                    return {
                        step: FormSteps.VeryficationCode, // wracamy do kroku wpisywania kodu
                        message: 'Wprowadzono nieprawidłowy kod',
                    }
                }
            } else if (error.fieldName === 'password') {
                return {
                    step: FormSteps.SetPassword,
                    password:
                        'Zbyt słabe hasło. Bezpieczne hasło powinno składać się z minimum 6 znaków i zawierać przynajmniej jeden symbol tj. @#$^_',
                }
            }
        }

        return {
            step: FormSteps.SetPassword,
            message: 'Wystąpił problem podczas resetowania hasła. Spróbuj ponownie za chwilę.',
        }
    }
}

// Formularz z adresem e-mail na który chcemy odzyskać hasło
const SendEmailForm: FC = (): ReactElement => {
    const [errors, setErrors] = useFormErrors()
    const [values, onChange] = useFormValues({
        email: '',
    })

    return (
        <Form className="reset-password" action="/password-reset" method="post" dispatchError={setErrors}>
            <Form.Group className="form-group" controlId="email">
                <Form.Label>Wprowadź adres e-mail użyty podczas rejestracji:</Form.Label>
                <Form.Control
                    type="email"
                    name="email"
                    value={values.email}
                    onChange={onChange}
                    required
                    autoFocus
                    isInvalid={!!errors.message}
                />
                <Form.Control.Feedback type="invalid">{errors.message}</Form.Control.Feedback>
            </Form.Group>

            <div className="d-grid">
                <Button variant="primary" type="submit">
                    Dalej
                </Button>
            </div>

            <p className="text-muted text-center mt-4 small">
                <Link className="text-decoration-none" to="/sign-in">
                    Powrót do ekranu logowania
                </Link>
            </p>
        </Form>
    )
}

interface PasswordResetFormParams {
    email: string
    verificationId: string
    verificationCode: string
}

// Formularz ustawiania nowego hasła
const PasswordResetForm: FC<PasswordResetFormParams> = ({ email, verificationId, verificationCode }): ReactElement => {
    const [errors, setErrors] = useFormErrors()
    const [values, onChange] = useFormValues({
        password: '',
        password_confirm: '',
    })

    let alert
    if (errors.message) {
        alert = (
            <Alert variant="danger" className="text-center">
                {errors.message}
            </Alert>
        )
    }

    return (
        <Form
            className="reset-password"
            action={`/password-reset/${verificationId}/${verificationCode}`}
            method="post"
            dispatchError={setErrors}>
            {alert}

            {/* Ukryte pole tekstowe z adresem e-mail na potrzeby menadżera haseł w przeglądarce */}
            <Form.Group className="form-group d-none" controlId="email">
                <Form.Label>Adres e-mail</Form.Label>
                <Form.Control type="email" name="email" autoComplete="username" value={email} readOnly />
            </Form.Group>

            <ChangePasswordFields values={values} errors={errors} onChange={onChange} />

            <div className="d-grid">
                <Button variant="primary" type="submit">
                    Dalej
                </Button>
            </div>

            <p className="text-muted text-center mt-4 small">
                <Link className="text-decoration-none" to="/sign-in">
                    Powrót do ekranu logowania
                </Link>
            </p>
        </Form>
    )
}

const PasswordResetPage: FC = (): ReactElement => {
    const { verificationId, verificationCode } = useParams()
    const email = useLoaderData() as string | null
    const { step } = (useActionData() as { step?: number }) || {}

    // Wyświetlenie podsumowania - najpierw przekierowujemy na początkowy
    // adres URL, a następnie wyświetlamy komunikat
    const navigate = useNavigate()
    if (step === FormSteps.Summary) {
        navigate('/password-reset', {
            state: { success: true },
        })
    }
    const location = useLocation()
    if (location.state?.success) {
        return (
            <div id="reset-password-page" className="p-3">
                <Form className="reset-password">
                    <Alert variant="success" className="text-center">
                        Twoje hasło zostało zmienione
                    </Alert>
                    <p className="text-muted text-center mt-4 small">
                        Powrót do{' '}
                        <Link className="text-decoration-none" to="/">
                            strony głównej
                        </Link>
                    </p>
                </Form>
            </div>
        )
    }

    // W pierwszym kroku wybieramy adres e-mail na który ma zostać
    // wysłany kod do odzyskiwania hasła
    if (step === FormSteps.SendEmail || !verificationId) {
        return (
            <div id="reset-password-page" className="p-3">
                <h1 className="h4 mb-3 text-center">Przypomnij hasło</h1>
                <SendEmailForm />
            </div>
        )
    }

    if (!email) {
        // Na tym etapie adres e-mail powinien być już znany i warunek
        // nie powinien nigdy być spełniony
        return <Navigate to="/password-reset" />
    }

    // Krok drugi - wprowadzenie kodu
    if (step === FormSteps.VeryficationCode || !verificationCode) {
        return (
            <div id="reset-password-page" className="p-3">
                <VeryficationCodeForm context="password-reset" email={email} verificationId={verificationId}>
                    Na adres <strong>{email}</strong> wysłaliśmy link weryfikacyjny. By ustawić nowe hasło, kliknij w
                    ten link lub wprowadź kod podany w mailu
                </VeryficationCodeForm>
            </div>
        )
    }

    return (
        <div id="reset-password-page" className="p-3">
            <h1 className="h4 mb-3 text-center">Utwórz nowe hasło</h1>
            <PasswordResetForm email={email} verificationId={verificationId} verificationCode={verificationCode} />
        </div>
    )
}

export default PasswordResetPage
