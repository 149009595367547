import { FC, ReactElement } from 'react'

const PrivacyPolicyPage: FC = (): ReactElement => {
    return (
        <div className="p-5">
            <h1 className="h4 mb-3">Polityka prywatności</h1>
        </div>
    )
}

export default PrivacyPolicyPage
