import { FC, ReactElement } from 'react'
import {
    LoaderFunction,
    ActionFunction,
    Link,
    Navigate,
    redirect,
    useParams,
    useLoaderData,
    useActionData,
} from 'react-router-dom'
import Form, { useFormValues, useFormErrors } from '../../lib/Form'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import api, { ApiError, ApiValidationError } from '../../lib/Api'
import AuthProvider, { TokenResponse, CLIENT_ID } from '../../lib/AuthProvider'
import VeryficationCodeForm from '../../components/VeryficationCodeForm'
import ChangePasswordFields from '../../components/ChangePasswordFields'

enum FormSteps {
    SendEmail = 1,
    VeryficationCode = 2,
    SetPassword = 3,
}

export const emailVeryficationLoader =
    (defaultUrl: string): LoaderFunction =>
    async ({ params: { verificationId } }) => {
        if (!verificationId) {
            return null
        }

        let email = AuthProvider.getVeryficationEmail(verificationId)

        if (!email) {
            try {
                const { data } = await api.get<{ email: string }>('/api/verifications/' + verificationId)
                email = data.email

                AuthProvider.setVeryficationEmail(verificationId, email)
            } catch (error) {}
        }

        if (!email) {
            return redirect(defaultUrl)
        }

        return email
    }

export const createAccountAction: ActionFunction = async ({ params, request }) => {
    const formData = await request.formData()

    // Pierwszy krok - generujemy nowy kod weryfikacyjny i przesyłamy
    // na wskazany adres e-mail
    if (!params.verificationId) {
        try {
            const email = formData.get('email') as string
            const {
                data: { verificationId },
            } = await api.post<{ verificationId: string }>('/api/verifications/registration', {
                email,
            })

            AuthProvider.setVeryficationEmail(verificationId, email)

            return redirect(`/create-account/${verificationId}`)
        } catch (error) {
            if (error instanceof ApiValidationError) {
                return {
                    step: FormSteps.SendEmail,
                    message: 'Wprowadzono nieprawidłowy adres e-mail.',
                }
            }
            if (error instanceof ApiError && error.code === 'conflict') {
                return {
                    step: FormSteps.SendEmail,
                    message: 'Na podany adres e-mail jest już zarejestrowane konto.',
                }
            }
            return {
                step: FormSteps.SendEmail,
                message: 'Wystąpił problem podczas zakładania konta. Spróbuj ponownie za chwilę.',
            }
        }
    }

    // Drugi krok - przekazujemy wprowadzony kod weryfikacyjny w adresie URL
    // i przechodzimy do kolejnego kroku
    if (!params.verificationCode) {
        const verificationId = params.verificationId
        const verificationCode = formData.get('verification_code') as string
        return redirect(`/create-account/${verificationId}/${verificationCode}`)
    }

    // Trzeci krok - utworzenie nowego hasła
    try {
        // Walidacja po stronie klienta jeszcze przed wysłaniem danych
        if (formData.get('password_confirm') !== formData.get('password')) {
            return {
                step: FormSteps.SetPassword,
                password_confirm: 'Wprowadzone hasła muszą być takie same.',
            }
        }

        // Przesyłamy nowe hasło na serwer
        const {
            data: { accessToken, refreshToken },
        } = await api.post<TokenResponse>(
            '/api/users',
            {
                verificationId: params.verificationId,
                verificationCode: params.verificationCode,
                password: formData.get('password'),
                clientId: CLIENT_ID,
            },
            {
                headers: {
                    // Nie chcemy przekazywać tokenu JWT
                    Authorization: null,
                },
            }
        )

        // Aktualizujemy tokeny autoryzacyjne
        AuthProvider.setTokens(accessToken, refreshToken)

        // Przekierowujemy na stronę główną
        return redirect('/')
    } catch (error) {
        if (error instanceof ApiValidationError) {
            if (error.fieldName === 'verificationCode') {
                if (error.details === 'Verification id not found or expired') {
                    return {
                        step: FormSteps.SendEmail, // wracamy do kroku wpisywania adresu e-mail
                        message: 'Przesłany kod stracił swoją ważność. Należy wygenerować nowy.',
                    }
                } else {
                    return {
                        step: FormSteps.VeryficationCode, // wracamy do kroku wpisywania kodu
                        message: 'Wprowadzono nieprawidłowy kod',
                    }
                }
            } else if (error.fieldName === 'password') {
                return {
                    step: FormSteps.SetPassword,
                    password:
                        'Zbyt słabe hasło. Bezpieczne hasło powinno składać się z minimum 6 znaków i zawierać przynajmniej jeden symbol tj. @#$^_',
                }
            }
        }

        return {
            step: FormSteps.SetPassword,
            message: 'Wystąpił problem podczas zakładania konta. Spróbuj ponownie za chwilę.',
        }
    }
}

// Formularz z adresem e-mail na który chcemy zarejestrować konto
const SendEmailForm: FC = (): ReactElement => {
    const [errors, setErrors] = useFormErrors()
    const [values, onChange] = useFormValues({
        email: '',
    })

    return (
        <Form className="create-account" action="/create-account" method="post" dispatchError={setErrors}>
            <Form.Group className="form-group" controlId="email">
                <Form.Label>Adres email</Form.Label>
                <Form.Control
                    type="email"
                    name="email"
                    value={values.email}
                    onChange={onChange}
                    required
                    autoFocus
                    isInvalid={!!errors.message}
                />
                <Form.Control.Feedback type="invalid">{errors.message}</Form.Control.Feedback>
            </Form.Group>

            <div className="d-grid">
                <Button variant="primary" type="submit">
                    Dalej
                </Button>
            </div>

            <p className="text-muted text-center mt-4 small">
                Posiadasz konto?{' '}
                <Link className="text-decoration-none" to="/sign-in">
                    Zaloguj się
                </Link>
            </p>
        </Form>
    )
}

interface CreateAccountFormParams {
    email: string
    verificationId: string
    verificationCode: string
}

// Formularz ustawiania nowego hasła
const CreateAccountForm: FC<CreateAccountFormParams> = ({ email, verificationId, verificationCode }): ReactElement => {
    const [errors, setErrors] = useFormErrors()
    const [values, onChange] = useFormValues({
        password: '',
        password_confirm: '',
        rules: false,
    })

    let alert
    if (errors.message) {
        alert = (
            <Alert variant="danger" className="text-center">
                {errors.message}
            </Alert>
        )
    }

    return (
        <Form
            className="create-account"
            action={`/create-account/${verificationId}/${verificationCode}`}
            method="post"
            dispatchError={setErrors}>
            {alert}

            {/* Ukryte pole tekstowe z adresem e-mail na potrzeby menadżera haseł w przeglądarce */}
            <Form.Group className="form-group d-none" controlId="email">
                <Form.Label>Adres e-mail</Form.Label>
                <Form.Control type="email" name="email" autoComplete="username" value={email} readOnly />
            </Form.Group>

            <ChangePasswordFields values={values} errors={errors} onChange={onChange} />

            <Form.Group className="form-group" controlId="rules">
                <Form.Check id="custom-switch">
                    <Form.Check.Input
                        required
                        name="rules"
                        isInvalid={!!errors.rules}
                        checked={values.rules}
                        onChange={onChange}
                    />
                    <Form.Check.Label className="small">
                        Oświadczam, że zapoznałem się z treścią{' '}
                        <Link
                            className="text-decoration-none"
                            to="/terms-of-service"
                            target="_blank"
                            rel="noopener noreferrer">
                            Regulaminu
                        </Link>{' '}
                        serwisu oraz{' '}
                        <Link
                            className="text-decoration-none"
                            to="/privacy-policy"
                            target="_blank"
                            rel="noopener noreferrer">
                            Polityką Prywatności
                        </Link>{' '}
                        i akceptuję ich postanowienia.
                    </Form.Check.Label>
                </Form.Check>
            </Form.Group>

            <div className="d-grid">
                <Button variant="primary" type="submit">
                    Utwórz konto
                </Button>
            </div>
        </Form>
    )
}

const CreateAccountPage: FC = (): ReactElement => {
    const { verificationId, verificationCode } = useParams()
    const email = useLoaderData() as string | null
    const { step } = (useActionData() as { step?: number }) || {}

    // W pierwszym kroku wybieramy adres e-mail na który ma zostać
    // wysłany kod do rejestracji
    if (step === FormSteps.SendEmail || !verificationId) {
        return (
            <div id="create-account-page" className="p-3">
                <h1 className="h4 mb-3 text-center">Rejestracja</h1>
                <SendEmailForm />
            </div>
        )
    }

    if (!email) {
        // Na tym etapie adres e-mail powinien być już znany i warunek
        // nie powinien nigdy być spełniony
        return <Navigate to="/create-account" />
    }

    // Krok drugi - wprowadzenie kodu
    if (step === FormSteps.VeryficationCode || !verificationCode) {
        return (
            <div id="create-account-page" className="p-3">
                <VeryficationCodeForm context="create-account" email={email} verificationId={verificationId}>
                    Na adres <strong>{email}</strong> wysłaliśmy link weryfikacyjny. By kontynuować zakładanie konta,
                    kliknij w ten link lub wprowadź kod podany w mailu
                </VeryficationCodeForm>
            </div>
        )
    }

    return (
        <div id="create-account-page" className="p-3">
            <h1 className="h4 mb-3 text-center">Rejestracja</h1>
            <CreateAccountForm email={email} verificationId={verificationId} verificationCode={verificationCode} />
        </div>
    )
}

export default CreateAccountPage
