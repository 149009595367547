import { FC, ReactElement, MouseEvent, useContext } from 'react'
import { ActionFunction, Navigate, Outlet, useNavigate, redirect } from 'react-router-dom'
import UserContext from '../../contexts/user'
import api, { ApiError, ApiValidationError } from '../../lib/Api'
import AuthProvider, { TokenResponse } from '../../lib/AuthProvider'
import Form, { useFormValues, useFormErrors } from '../../lib/Form'
import { PasswordField } from '../../components/ChangePasswordFields'
import InputGroup from 'react-bootstrap/InputGroup'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import Modal from 'react-bootstrap/Modal'

export const changePasswordAction: ActionFunction = async ({ request }) => {
    const formData = await request.formData()

    try {
        // Walidacja po stronie klienta jeszcze przed wysłaniem danych
        if (formData.get('password_confirm') !== formData.get('new_password')) {
            return {
                password_confirm: 'Wprowadzone hasła muszą być takie same.',
            }
        }

        // Przesyłamy nowe hasło na serwer
        const {
            data: { accessToken, refreshToken },
        } = await api.put<TokenResponse>('/api/users/password', {
            oldPassword: formData.get('current_password'),
            newPassword: formData.get('new_password'),
        })

        // Aktualizujemy tokeny autoryzacyjne
        AuthProvider.setTokens(accessToken, refreshToken)

        // Zamykamy okno
        return redirect('..')
    } catch (error) {
        if (error instanceof ApiValidationError) {
            if (error.fieldName === 'oldPassword') {
                return {
                    current_password: 'Nieprawidłowe obecne hasło',
                }
            } else if (error.fieldName === 'newPassword') {
                return {
                    new_password:
                        'Zbyt słabe hasło. Bezpieczne hasło powinno składać się z minimum 6 znaków i zawierać przynajmniej jeden symbol tj. @#$^_',
                }
            }
        } else if (error instanceof ApiError) {
            if (error.code === 'invalid_credentials') {
                return {
                    current_password: 'Nieprawidłowe obecne hasło',
                }
            }
        }

        return {
            message: 'Nie udało się zmienić hasła. Spróbuj ponownie za chwilę.',
        }
    }
}

export const ChangePasswordForm: FC = (): ReactElement => {
    const navigate = useNavigate()
    const handleClose = () => navigate('..', { replace: true })

    const [errors, setErrors] = useFormErrors()
    const [values, onChange] = useFormValues({
        current_password: '',
        new_password: '',
        password_confirm: '',
    })

    const { user } = useContext(UserContext)

    let alert
    if (errors.message) {
        alert = (
            <Alert variant="danger" className="text-center">
                {errors.message}
            </Alert>
        )
    }

    return (
        <Modal show={true} onHide={handleClose} backdrop="static" centered>
            <Modal.Header closeButton>
                <Modal.Title>Zmiana hasła</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {alert}
                <Form method="post" dispatchError={setErrors}>
                    {/* Ukryte pole tekstowe z adresem e-mail na potrzeby menadżera haseł w przeglądarce */}
                    <Form.Group className="form-group d-none" controlId="email">
                        <Form.Label>Adres e-mail</Form.Label>
                        <Form.Control
                            type="email"
                            name="email"
                            autoComplete="username"
                            value={user?.username}
                            readOnly
                        />
                    </Form.Group>

                    <Form.Group className="form-group" controlId="current_password">
                        <Form.Label>Obecne hasło</Form.Label>
                        <PasswordField
                            name="current_password"
                            value={values.current_password as string}
                            error={errors.current_password}
                            autoComplete="current-password"
                            autoFocus={true}
                            onChange={onChange}
                        />
                    </Form.Group>

                    <Form.Group className="form-group" controlId="new_password">
                        <Form.Label>Nowe hasło</Form.Label>
                        <PasswordField
                            name="new_password"
                            value={values.new_password as string}
                            error={errors.new_password}
                            autoComplete="new-password"
                            onChange={onChange}
                        />
                    </Form.Group>

                    <Form.Group className="form-group" controlId="password_confirm">
                        <Form.Label>Potwierdź hasło</Form.Label>
                        <Form.Control
                            type="password"
                            name="password_confirm"
                            autoComplete="new-password"
                            value={values.password_confirm as string}
                            onChange={onChange}
                            required
                            isInvalid={!!errors.password_confirm}
                        />
                        <Form.Control.Feedback type="invalid">{errors.password_confirm}</Form.Control.Feedback>
                    </Form.Group>

                    <p className="text-muted mt-4 small">
                        Pamiętaj, że im dłuższe hasło tym jest bezpieczniejsze. Użyj hasła zawierającego minimum minimum
                        6 znaków i przynajmniej jeden znak specjalny
                    </p>

                    <div className="d-grid">
                        <Button variant="primary" type="submit">
                            Zapisz
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export const deleteAccountAction: ActionFunction = async ({ request }) => {
    if (request.method !== 'DELETE') {
        // Zamykamy okno
        return redirect('..')
    }

    const formData = await request.formData()

    try {
        // Kasujemy konto użytkownika
        const {
            data: { success },
        } = await api.delete<{ success: boolean }>('/api/users', {
            data: {
                password: formData.get('current_password'),
            },
        })

        if (!success) {
            throw new Error()
        }

        // Wylogowujemy
        await AuthProvider.logout()

        // Przechodzimy do strony głównej
        return redirect('/')
    } catch (error) {
        if (error instanceof ApiValidationError) {
            if (error.fieldName === 'password') {
                return {
                    current_password: 'Nieprawidłowe hasło',
                }
            }
        } else if (error instanceof ApiError) {
            if (error.code === 'invalid_credentials') {
                return {
                    current_password: 'Nieprawidłowe hasło',
                }
            }
        }

        return {
            message: 'Nie udało się usunąć konta. Spróbuj ponownie za chwilę.',
        }
    }
}

export const DeleteAccount: FC = (): ReactElement => {
    const navigate = useNavigate()
    const handleClose = () => navigate('..', { replace: true })

    const [errors, setErrors] = useFormErrors()
    const [values, onChange] = useFormValues({
        current_password: '',
    })

    const { user } = useContext(UserContext)

    return (
        <Modal show={true} onHide={handleClose} size="sm" centered>
            <Modal.Header closeButton>
                <Modal.Title>Usuwanie konta</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {errors.message && (
                    <Alert variant="danger" className="text-center">
                        {errors.message}
                    </Alert>
                )}
                <Form method="delete" dispatchError={setErrors}>
                    {/* Ukryte pole tekstowe z adresem e-mail na potrzeby menadżera haseł w przeglądarce */}
                    <Form.Group className="form-group d-none" controlId="email">
                        <Form.Label>Adres e-mail</Form.Label>
                        <Form.Control
                            type="email"
                            name="email"
                            autoComplete="username"
                            value={user?.username}
                            readOnly
                        />
                    </Form.Group>

                    <Form.Group className="form-group" controlId="current_password">
                        <Form.Label>Aby usunąć konto wprowadź swoje obecne hasło:</Form.Label>
                        <PasswordField
                            name="current_password"
                            value={values.current_password as string}
                            error={errors.current_password}
                            autoComplete="current-password"
                            autoFocus={true}
                            onChange={onChange}
                        />
                    </Form.Group>

                    <p className="small">
                        Pamiętaj, że usuniętego konta nie da się przywrócić, a wszystkie dostępne środki oraz bilety na
                        urządzeniach mobilnych przepadną
                    </p>

                    <div className="d-block text-end">
                        <Button variant="secondary" type="button" onClick={handleClose}>
                            Anuluj
                        </Button>{' '}
                        <Button variant="primary" type="submit">
                            Usuń konto
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

const SettingsPage: FC = (): ReactElement => {
    const navigate = useNavigate()

    const showModal = (url: string) => (e: MouseEvent<HTMLElement>) => {
        e.preventDefault()
        navigate(url, { replace: true })
    }

    const { user } = useContext(UserContext)
    if (!user) {
        return <Navigate to="/sign-in" />
    }

    return (
        <>
            <h1 className="h4 mb-3">Ustawienia konta</h1>

            <h2 className="h5 mb-3">Dane konta</h2>
            <Form>
                <Form.Group className="form-group" controlId="info-email">
                    <Form.Label>Adres email</Form.Label>
                    <Form.Control type="email" name="email" value={user.username} readOnly />
                </Form.Group>
                <Form.Group className="form-group" controlId="info-password">
                    <Form.Label>Hasło</Form.Label>
                    <InputGroup>
                        <Form.Control type="password" name="password" value="********" readOnly />
                        <Button variant="secondary" onClick={showModal('./change-password')}>
                            Zmień
                        </Button>
                    </InputGroup>
                </Form.Group>
            </Form>

            <h2 className="h5 mb-3">Usuwanie konta</h2>
            <Form>
                <p className="small">
                    Kliknij w poniższy przycisk aby usunąć konto z naszego systemu. Upewnij się, że na pewno chcesz to
                    zrobić - wszystkie dostępne środki oraz bilety na urządzeniach mobilnych przepadną. Twojego konta
                    nie będzie można przywrócić.
                </p>
                <p className="small">Bilety zakodowane na kartach płatniczych pozostaną aktywne.</p>
                <Button variant="secondary" onClick={showModal('./delete-account')}>
                    Usuń konto
                </Button>
            </Form>

            <Outlet />
        </>
    )
}

export default SettingsPage
