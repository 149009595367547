import { FC, ReactElement, PropsWithChildren, createContext } from 'react'
import { useRouteLoaderData } from 'react-router-dom'
import { User } from '../lib/AuthProvider'

interface IUserContext {
    user: User | null
}

export const UserContext = createContext<IUserContext>({ user: null })

export const UserProvider: FC<PropsWithChildren> = ({ children }: PropsWithChildren): ReactElement => {
    const user = useRouteLoaderData('root') as User | null
    const context: IUserContext = { user }

    return <UserContext.Provider value={context}>{children}</UserContext.Provider>
}

export default UserContext
