import { FC, ReactElement, useEffect } from 'react'
import { createBrowserRouter, RouterProvider, Outlet, useLocation } from 'react-router-dom'
import AuthProvider, { createAuthenticatedLoader } from './lib/AuthProvider'
import { UserProvider } from './contexts/user'
import RequireAuth from './components/RequireAuth'

import BaseLayout from './layouts/BaseLayout'
import DefaultLayout from './layouts/DefaultLayout'

import HomePage from './pages/HomePage'

import LoginPage, { loginAction } from './pages/auth/LoginPage'
import LogoutPage, { logoutAction } from './pages/auth/LogoutPage'
import CreateAccountPage, { emailVeryficationLoader, createAccountAction } from './pages/auth/CreateAccountPage'
import PasswordResetPage, { passwordResetAction } from './pages/auth/PasswordResetPage'

import AccountPage from './pages/account/AccountPage'
import PassengersPage, {
    passengersLoader,
    passengerSaveAction,
    passengerDeleteAction,
    PassengerAddForm,
    PassengerEditForm,
    PassengersDeleteForm,
} from './pages/account/PassengersPage'
import PaymentCardsPage, {
    AddPaymentCardPage,
    PaymentCardLandingPage,
    paymentCardsLoader,
    paymentCardsAction,
} from './pages/account/PaymentCardsPage'
import MobileDevicesPage, { mobileDevicesLoader } from './pages/account/MobileDevicesPage'
import SettingsPage, {
    ChangePasswordForm,
    changePasswordAction,
    DeleteAccount,
    deleteAccountAction,
} from './pages/account/SettingsPage'

import TicketsPage, { ticketsLoader } from './pages/TicketsPage'
import BuyTicketPage, { ticketsOfferLoader } from './pages/BuyTicketPage'
import PaymentStatusPage from './pages/PaymentStatusPage'
import JourneysPage, { journeysLoader } from './pages/JourneysPage'
import TermsOfServicePage from './pages/TermsOfServicePage'
import PrivacyPolicyPage from './pages/PrivacyPolicyPage'
import ErrorPage from './pages/ErrorPage'

const ScrollToTop = () => {
    const { pathname } = useLocation()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [pathname])

    return null
}

const router = createBrowserRouter([
    {
        id: 'root',
        path: '/',
        loader: async () => {
            const user = await AuthProvider.user()
            return user
        },
        shouldRevalidate: ({ currentUrl, formMethod }) => {
            const authActions: {
                method: 'get' | 'post' | 'put' | 'delete'
                url: string | RegExp
            }[] = [
                { method: 'post', url: '/sign-in' },
                { method: 'post', url: '/logout' },
                { method: 'post', url: /^\/create-account\/[0-9a-f-]+\/\d+$/i },
                { method: 'post', url: /^\/password-reset\/[0-9a-f-]+\/\d+$/i },
                { method: 'delete', url: '/your-account/settings/delete-account' },
            ]

            return (
                authActions.findIndex(({ method, url }) =>
                    formMethod?.toLowerCase() === method && url instanceof RegExp
                        ? url.test(currentUrl.pathname)
                        : currentUrl.pathname === url
                ) !== -1
            )
        },
        element: (
            <UserProvider>
                <ScrollToTop />
                <Outlet />
            </UserProvider>
        ),
        errorElement: (
            <UserProvider>
                <DefaultLayout>
                    <ErrorPage />
                </DefaultLayout>
            </UserProvider>
        ),
        children: [
            {
                index: true,
                element: (
                    <BaseLayout>
                        <HomePage />
                    </BaseLayout>
                ),
            },
            {
                path: '/sign-in',
                element: (
                    <DefaultLayout className="sign-in-page">
                        <LoginPage />
                    </DefaultLayout>
                ),
                action: loginAction,
            },
            {
                path: '/create-account/:verificationId?/:verificationCode?',
                element: (
                    <DefaultLayout>
                        <CreateAccountPage />
                    </DefaultLayout>
                ),
                loader: emailVeryficationLoader('/create-account'),
                action: createAccountAction,
            },
            {
                path: '/password-reset/:verificationId?/:verificationCode?',
                element: (
                    <DefaultLayout>
                        <PasswordResetPage />
                    </DefaultLayout>
                ),
                loader: emailVeryficationLoader('/password-reset'),
                action: passwordResetAction,
            },
            {
                path: 'your-account',
                element: (
                    <DefaultLayout>
                        <RequireAuth>
                            <AccountPage />
                        </RequireAuth>
                    </DefaultLayout>
                ),
                loader: createAuthenticatedLoader(null),
                children: [
                    {
                        id: 'passengers',
                        path: 'passengers',
                        element: <PassengersPage />,
                        loader: passengersLoader,
                        children: [
                            {
                                path: 'add',
                                element: <PassengerAddForm />,
                                action: passengerSaveAction,
                            },
                            {
                                path: 'edit/:passengerId',
                                element: <PassengerEditForm />,
                                action: passengerSaveAction,
                            },
                            {
                                path: 'delete/:passengerId',
                                element: <PassengersDeleteForm />,
                                action: passengerDeleteAction,
                            },
                        ],
                    },
                    {
                        path: 'cards',
                        element: <PaymentCardsPage />,
                        loader: paymentCardsLoader,
                        action: paymentCardsAction,
                        children: [
                            {
                                path: 'landing/:transactionId',
                                element: <PaymentCardLandingPage />,
                            },
                        ],
                    },
                    {
                        path: 'cards/add/:passengerId?',
                        element: <AddPaymentCardPage />,
                        loader: passengersLoader,
                    },
                    {
                        path: 'devices',
                        element: <MobileDevicesPage />,
                        loader: mobileDevicesLoader,
                    },
                    {
                        path: 'settings',
                        element: <SettingsPage />,
                        children: [
                            {
                                path: 'change-password',
                                element: <ChangePasswordForm />,
                                action: changePasswordAction,
                            },
                            {
                                path: 'delete-account',
                                element: <DeleteAccount />,
                                action: deleteAccountAction,
                            },
                        ],
                    },
                ],
            },
            {
                path: '/logout',
                element: (
                    <DefaultLayout>
                        <LogoutPage />
                    </DefaultLayout>
                ),
                action: logoutAction,
            },
            {
                path: '/tickets',
                element: (
                    <DefaultLayout>
                        <RequireAuth>
                            <TicketsPage />
                        </RequireAuth>
                    </DefaultLayout>
                ),
                loader: createAuthenticatedLoader(ticketsLoader),
            },
            {
                path: '/tickets/buy',
                element: (
                    <DefaultLayout>
                        <RequireAuth>
                            <BuyTicketPage />
                        </RequireAuth>
                    </DefaultLayout>
                ),
                loader: createAuthenticatedLoader(ticketsOfferLoader),
            },
            {
                path: '/tickets/payment/:orderId',
                element: (
                    <DefaultLayout>
                        <PaymentStatusPage />
                    </DefaultLayout>
                ),
            },
            {
                path: '/journey-history/:page?',
                element: (
                    <DefaultLayout>
                        <RequireAuth>
                            <JourneysPage />
                        </RequireAuth>
                    </DefaultLayout>
                ),
                loader: createAuthenticatedLoader(journeysLoader),
            },
            {
                path: '/terms-of-service',
                element: (
                    <DefaultLayout>
                        <TermsOfServicePage />
                    </DefaultLayout>
                ),
            },
            {
                path: '/privacy-policy',
                element: (
                    <DefaultLayout>
                        <PrivacyPolicyPage />
                    </DefaultLayout>
                ),
            },
            {
                // Bez tej ścieżki też byłby wyrzucany błąd 404, ale
                // nie byłby uruchamiany root loader i nie mielibyśmy
                // informacji o zalogowanym użytkowniku na stronie
                // z błędem
                path: '*',
                loader: () => {
                    throw new Response('Not Found', { status: 404 })
                },
            },
        ],
    },
])

const AppRoutes: FC = (): ReactElement => {
    return <RouterProvider router={router} />
}

export default AppRoutes
