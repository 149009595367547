import { FC, ReactElement } from 'react'
import { ActionFunction, Link } from 'react-router-dom'
import AuthProvider from '../../lib/AuthProvider'
import { ApiError } from '../../lib/Api'
import LoginForm from '../../components/LoginForm'

export const loginAction: ActionFunction = async ({ request }) => {
    const formData = await request.formData()

    try {
        return await AuthProvider.login(formData.get('email') as string, formData.get('password') as string)
    } catch (error) {
        let message = 'Logowanie nie powiodło się'
        if (error instanceof ApiError) {
            if (error.code === 'invalid_credentials') {
                message = 'Nieprawidłowy login lub hasło'
            }
        }

        return {
            message,
        }
    }
}

const LoginPage: FC = (): ReactElement => {
    return (
        <>
            <div className="frame">
                <LoginForm />
            </div>
            <div className="frame registration-benefits">
                <h2 className="h4 mb-3">
                    Nie masz jeszcze e-biletu?
                    <br />
                    <span>Załóż konto</span> i ruszaj w drogę!
                </h2>

                <h3 className="h6">Dlaczego warto wybrać e-bilet?</h3>

                <ul>
                    <li>Kupujesz bilety online, gdziekolwiek jesteś</li>
                    <li>Wiesz, ile wydałeś już na przejazdy</li>
                    <li>Możesz kupić bilet rodzinie</li>
                    <li>Chronisz środowisko, wybierając e-bilet</li>
                </ul>

                <Link className="btn btn-primary" to="/create-account">
                    Utwórz nowe konto
                </Link>
            </div>
        </>
    )
}

export default LoginPage
