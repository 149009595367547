import { FC, ReactElement, MouseEvent, useState } from 'react'
import { LoaderFunction, useLoaderData, useRevalidator } from 'react-router-dom'
import api, { ApiValidationError } from '../../lib/Api'
import Form, { SelfValidatingForm, useFormValues, useFormErrors } from '../../lib/Form'
import Table from 'react-bootstrap/Table'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMobileScreen, faTrashAlt, faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import { ReactComponent as GooglePlayBadge } from '../../assets/images/google-play-badge.svg'
import { ReactComponent as AppStoreBadge } from '../../assets/images/app-store-badge.svg'

export interface TicketMedium {
    ticketMediumId: string
    name: string
    createDate: string
}

export const mobileDevicesLoader: LoaderFunction = async () => {
    return await api.get('/api/ticket-mediums').then(r => r.data.ticketMediums)
}

type ModalType = 'edit' | 'delete' | undefined

interface ModalWindowParams {
    device: TicketMedium
    handleClose: () => void
}

const EditMobileDevice: FC<ModalWindowParams> = ({ device, handleClose }): ReactElement => {
    const [errors, setErrors] = useFormErrors()
    const [values, onChange] = useFormValues({
        device_name: device.name,
    })

    const revalidator = useRevalidator()

    const handleSubmit = async (formData: FormData) => {
        const ticketMediumId = device.ticketMediumId
        try {
            // Edycja nazwy urządzenia
            const {
                data: { success },
            } = await api.put<{ success: boolean }>(`/api/ticket-mediums/${ticketMediumId}`, {
                name: formData.get('device_name'),
            })

            if (!success) {
                throw new Error()
            }

            // Pobieramy na nowo listę urządzeń
            revalidator.revalidate()

            handleClose()
        } catch (error) {
            if (error instanceof ApiValidationError) {
                setErrors({
                    device_name: 'Nieprawidłowa wartość',
                })
            } else {
                setErrors({
                    message: 'Nie udało się zapisać zmian. Spróbuj ponownie za chwilę.',
                })
            }
        }
    }

    return (
        <Modal show={true} onHide={handleClose} size="sm" centered>
            <Modal.Header closeButton>
                <Modal.Title>Edycja urządzenia</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {errors.message && (
                    <Alert variant="danger" className="text-center">
                        {errors.message}
                    </Alert>
                )}
                <SelfValidatingForm method="put" dispatchError={setErrors} onSubmit={handleSubmit}>
                    <Form.Group className="form-group" controlId="device_name">
                        <Form.Label>Nazwa urządzenia</Form.Label>
                        <Form.Control
                            type="text"
                            name="device_name"
                            maxLength={40}
                            value={values.device_name}
                            onChange={onChange}
                            autoFocus
                            required
                            isInvalid={!!errors.device_name}
                        />
                    </Form.Group>

                    <div className="d-grid">
                        <Button variant="primary" type="submit">
                            Zapisz
                        </Button>
                    </div>
                </SelfValidatingForm>
            </Modal.Body>
        </Modal>
    )
}

const DeleteMobileDevice: FC<ModalWindowParams> = ({ device, handleClose }): ReactElement => {
    const [errors, setErrors] = useFormErrors()

    const revalidator = useRevalidator()

    const handleSubmit = async (formData: FormData) => {
        const ticketMediumId = device.ticketMediumId
        try {
            // Usuwanie urządzenia
            const {
                data: { success },
            } = await api.delete<{ success: boolean }>(`/api/ticket-mediums/${ticketMediumId}`)

            if (!success) {
                throw new Error()
            }

            // Pobieramy na nowo listę urządzeń
            revalidator.revalidate()

            handleClose()
        } catch (error) {
            setErrors({
                message: 'Nie udało się usunąć urządzenia. Spróbuj ponownie za chwilę.',
            })
        }
    }

    return (
        <Modal show={true} onHide={handleClose} size="sm" centered>
            <Modal.Header closeButton>
                <Modal.Title>Usuwanie urządzenia</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {errors.message && (
                    <Alert variant="danger" className="text-center">
                        {errors.message}
                    </Alert>
                )}
                <SelfValidatingForm method="delete" dispatchError={setErrors} onSubmit={handleSubmit}>
                    <input type="hidden" name="ticket_medium_id" value={device.ticketMediumId} />

                    <p className="small">
                        Usuń urządzenie tylko jeśli masz pewność, że urządzenie nie będzie już więcej używane.
                    </p>

                    <p className="small">
                        Pamiętaj, że stracisz dostęp do wszystkich biletów zapisanych na urządzeniu.
                    </p>

                    <div className="d-block text-end">
                        <Button variant="secondary" type="button" onClick={handleClose}>
                            Anuluj
                        </Button>{' '}
                        <Button variant="primary" type="submit">
                            Usuń urządzenie
                        </Button>
                    </div>
                </SelfValidatingForm>
            </Modal.Body>
        </Modal>
    )
}

const MobileDevicesPage: FC = (): ReactElement => {
    const ticketMediums = useLoaderData() as TicketMedium[]

    const [currentModal, setCurrentModal] = useState<{ type: ModalType; device?: TicketMedium }>({
        type: undefined,
        device: undefined,
    })

    const handleAction = (type: ModalType, device: TicketMedium) => (e: MouseEvent<HTMLElement>) => {
        e.preventDefault()
        setCurrentModal({ type, device })
    }

    const handleClose = () => setCurrentModal({ type: undefined, device: undefined })

    let modal: ReactElement | null = null
    if (currentModal.device && currentModal.type === 'edit') {
        modal = <EditMobileDevice device={currentModal.device} handleClose={handleClose} />
    } else if (currentModal.device && currentModal.type === 'delete') {
        modal = <DeleteMobileDevice device={currentModal.device} handleClose={handleClose} />
    }

    return (
        <>
            <h1 className="h4 mb-3">Urządzenia mobilne</h1>

            <h2 className="h5 mb-3">Zarejestrowane urządzenia</h2>

            {ticketMediums.length ? (
                <Table responsive hover>
                    <thead>
                        <tr>
                            <th scope="col">Nazwa</th>
                            <th scope="col" className="text-center">
                                Data rejestracji
                            </th>
                            <th scope="col" className="text-end"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {ticketMediums.map(item => (
                            <tr key={item.ticketMediumId}>
                                <td>
                                    <FontAwesomeIcon icon={faMobileScreen} fixedWidth={true} /> {item.name}
                                </td>
                                <td className="text-center">{new Date(item.createDate).toLocaleDateString()}</td>
                                <td width="70" className="text-end">
                                    <button
                                        className="btn btn-link p-0 text-decoration-none text-muted"
                                        title="Edytuj"
                                        aria-label="Edytuj"
                                        onClick={handleAction('edit', item)}>
                                        <FontAwesomeIcon icon={faPenToSquare} fixedWidth={true} />
                                    </button>{' '}
                                    <button
                                        className="btn btn-link p-0 text-decoration-none text-muted"
                                        title="Usuń"
                                        aria-label="Usuń"
                                        onClick={handleAction('delete', item)}>
                                        <FontAwesomeIcon icon={faTrashAlt} fixedWidth={true} />
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            ) : (
                <p className="mb-3">
                    Nie znaleziono zarejestrowanych urządzeń. Zainstaluj aplikację na swoim telefonie i zaloguj się na
                    swoje konto.
                </p>
            )}

            {modal}

            <h2 className="h5 mb-3">Pobierz aplikację</h2>

            <div className="row">
                <a
                    href="https://play.google.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Pobierz z Google Play"
                    className="col text-center">
                    <GooglePlayBadge preserveAspectRatio="xMaxYMid meet" className="w-100" height="60" />
                </a>{' '}
                <a
                    href="https://www.apple.com/pl/app-store/"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Pobierz z App Store"
                    className="col text-center">
                    <AppStoreBadge preserveAspectRatio="xMinYMid meet" className="w-100" height="60" />
                </a>
            </div>

            <ul className="small mt-3">
                <li>Google Play i logo Google Play są znakami towarowymi firmy Google LLC.</li>
                <li>
                    App Store jest znakiem towarowym firmy Apple Inc. zastrzeżonym w Stanach Zjednoczonych i innych
                    krajach.
                </li>
            </ul>
        </>
    )
}

export default MobileDevicesPage
