import jwtDecode, { JwtPayload as JwtPayloadBase } from 'jwt-decode'
import { LoaderFunction, LoaderFunctionArgs, redirect } from 'react-router-dom'
import api, { ApiError, OAUTH_URL } from './Api'

export const CLIENT_ID = 12345

const SIGN_IN_URL = '/sign-in'
const RETURN_URL_PARAM = 'returnUrl'

export interface User {
    username: string
    user_id: number
    client_id: number
}

interface JwtPayload extends JwtPayloadBase, User {}

class JwtToken {
    readonly payload: JwtPayload | null

    constructor(readonly hash: string | null) {
        this.payload = hash ? jwtDecode<JwtPayload>(hash) : null
    }

    public toString = (): string => {
        return this.hash ?? ''
    }

    public expireIn = (): number => {
        if (!this.payload) {
            // Jak nie ma tokenu to traktujemy jako wygaśnięte
            return -1
        }

        if (!this.payload.exp) {
            // Token ważny bezterminowo
            return Number.POSITIVE_INFINITY
        }

        // Za ile sekund token wygaśnie
        return this.payload.exp - new Date().getTime() / 1000
    }

    public expired = (): boolean => {
        return this.expireIn() <= 0
    }
}

export interface TokenResponse {
    accessToken: string
    accessTokenExpiration: string
    refreshToken: string
    refreshTokenExpiration: string
}

interface IAuthProvider {
    login: (username: string, password: string) => Promise<Response>
    logout: () => Promise<void>
    getToken: () => Promise<JwtToken>
    setTokens: (accessToken: string, refreshToken: string) => JwtToken
    user: () => Promise<User | null>
    getVeryficationEmail: (verificationId: string) => string | null
    setVeryficationEmail: (verificationId: string, email: string) => void
}

export const AuthProvider: IAuthProvider = {
    login: async (username: string, password: string): Promise<Response> => {
        const { data } = await api.post<TokenResponse>(OAUTH_URL, {
            grantType: 'password',
            clientId: CLIENT_ID,
            username,
            password,
        })

        AuthProvider.setTokens(data.accessToken, data.refreshToken)

        const returnUrl = new URLSearchParams(window.location.search).get(RETURN_URL_PARAM) ?? '/'

        return redirect(returnUrl)
    },

    logout: async (): Promise<void> => {
        try {
            await AuthProvider.getToken()

            await api.delete(OAUTH_URL)
        } catch (error) {}

        localStorage.removeItem('access_token')
        localStorage.removeItem('refresh_token')
    },

    getToken: async (): Promise<JwtToken> => {
        const accessToken = new JwtToken(localStorage.getItem('access_token'))

        if (accessToken.expired()) {
            localStorage.removeItem('access_token')
        }

        // Tokeny, którym została ważność mniej niż 15 minut, muszą zostać
        // odświeżone ze względu na możliwe niewielkie różnice w ustawieniach
        // zegara
        if (accessToken.expireIn() >= 15 * 60) {
            return accessToken
        }

        const refreshToken = new JwtToken(localStorage.getItem('refresh_token'))
        if (refreshToken.expired()) {
            localStorage.removeItem('refresh_token')

            throw new Error('There is no valid token. Please sign in.')
        }

        try {
            const { data } = await api.post<TokenResponse>(
                OAUTH_URL,
                {
                    grantType: 'refresh_token',
                    refreshToken: refreshToken.toString(),
                    clientId: CLIENT_ID,
                },
                {
                    timeout: 3000, // 3 sec
                }
            )

            return AuthProvider.setTokens(data.accessToken, data.refreshToken)
        } catch (error) {
            if (error instanceof ApiError) {
                if (error.code === 'invalid_token') {
                    // Kasujemy token skoro jest nieprawidłowy
                    localStorage.removeItem('refresh_token')
                }
            }

            throw error
        }
    },

    setTokens: (accessToken: string, refreshToken: string) => {
        const authToken = new JwtToken(accessToken)

        localStorage.setItem('access_token', accessToken)
        localStorage.setItem('refresh_token', refreshToken)

        return authToken
    },

    user: async (): Promise<User | null> => {
        try {
            const token = await AuthProvider.getToken()
            return token.payload
        } catch (error) {
            return null
        }
    },

    getVeryficationEmail: (verificationId: string): string | null => {
        return sessionStorage.getItem('verification_' + verificationId)
    },

    setVeryficationEmail: (verificationId: string, email: string) => {
        sessionStorage.setItem('verification_' + verificationId, email)
    },
}

type DataFunctionValue = Response | NonNullable<unknown> | null

export interface AuthenticatedLoaderFunctionArgs extends LoaderFunctionArgs {
    user?: User
    token?: JwtToken
}

export interface AuthenticatedLoaderFunction {
    (args: AuthenticatedLoaderFunctionArgs): Promise<DataFunctionValue> | DataFunctionValue
}

export const createAuthenticatedLoader =
    (loader: AuthenticatedLoaderFunction | null = null): LoaderFunction =>
    async (args: LoaderFunctionArgs) => {
        const authArgs = args as AuthenticatedLoaderFunctionArgs
        try {
            authArgs.token = await AuthProvider.getToken()
            const userData = authArgs.token.payload
            if (userData) {
                authArgs.user = {
                    username: userData.username,
                    user_id: userData.user_id,
                    client_id: userData.client_id,
                }
            }
        } catch (error) {}

        if (!authArgs.user) {
            const requestUrl = new URL(authArgs.request.url)
            return redirect(
                SIGN_IN_URL + '?' + new URLSearchParams({ [RETURN_URL_PARAM]: requestUrl.pathname }).toString()
            )
        }

        if (loader) {
            return loader(authArgs)
        }

        return null
    }

export default AuthProvider
