import { FC, ReactElement, ReactNode } from 'react'
import BaseLayout from './BaseLayout'

type Props = {
    className?: string
    children?: ReactNode
}

const DefaultLayout: FC<Props> = ({ className, children } = { className: '', children: null }): ReactElement => {
    return (
        <BaseLayout>
            <div id="main-content">
                <div className={'container container-sm ' + className}>{children}</div>
            </div>
        </BaseLayout>
    )
}

export default DefaultLayout
