import { FC, ReactElement } from 'react'
import { Navigate, RelativeRoutingType, useHref } from 'react-router-dom'
import BootstrapPagination, { PaginationProps } from 'react-bootstrap/Pagination'
import BootstrapPageItem from 'react-bootstrap/PageItem'

interface Params extends PaginationProps {
    currentPage: number
    totalPages: number
    href: (page: number) => string
    relative?: RelativeRoutingType
}

enum PageItemType {
    First = 'first',
    Prev = 'prev',
    Ellipsis = 'ellipsis',
    Next = 'next',
    Last = 'last',
}

const PageItem: FC<{ type: PageItemType | number; to?: string; active?: boolean; relative?: RelativeRoutingType }> = ({
    type,
    to,
    active,
    relative,
}): ReactElement => {
    const href = useHref(to ?? '.', { relative: relative ?? 'route' })

    let text: string | ReactElement
    let label: string | undefined

    if (typeof type === 'number') {
        text = type.toString()
    } else {
        switch (type) {
            case PageItemType.First:
                text = <>&laquo;</>
                break
            case PageItemType.Prev:
                text = <>&lsaquo;</>
                label = 'Poprzedni'
                break
            case PageItemType.Ellipsis:
                text = <>&hellip;</>
                label = 'Więcej'
                break
            case PageItemType.Next:
                text = <>&rsaquo;</>
                label = 'Następny'
                break
            case PageItemType.Last:
                text = <>&raquo;</>
                break
        }
    }

    return (
        <BootstrapPageItem active={active} href={to ? href : undefined}>
            <span aria-hidden="true">{text}</span>
            {label && <span className="visually-hidden">{label}</span>}
        </BootstrapPageItem>
    )
}

const Pagination: FC<Params> = ({ currentPage, totalPages, href, relative, ...props }): ReactElement => {
    const items: ReactElement[] = []

    if (currentPage > totalPages) {
        return <Navigate to={href(totalPages)} relative={relative} />
    }

    let fromPage = currentPage - 2
    let toPage = currentPage + 2
    if (fromPage < 1) {
        toPage += 1 - fromPage
        fromPage = 1
    }
    if (toPage > totalPages) {
        fromPage -= toPage - totalPages
        toPage = totalPages
    }
    if (fromPage < 1) {
        fromPage = 1
    }

    if (currentPage > 1) {
        items.push(<PageItem key="prev" type={PageItemType.Prev} to={href(currentPage - 1)} relative={relative} />)
    }

    if (fromPage >= 3) {
        items.push(<PageItem key="first" type={1} to={href(1)} relative={relative} />)
    }
    if (fromPage >= 2) {
        items.push(
            <PageItem key="ellipsis1" type={PageItemType.Ellipsis} to={href(fromPage - 1)} relative={relative} />
        )
    }

    for (let i = fromPage; i <= toPage; i++) {
        items.push(<PageItem key={i} type={i} to={href(i)} active={i === currentPage} relative={relative} />)
    }

    if (totalPages - toPage >= 1) {
        items.push(<PageItem key="ellipsis2" type={PageItemType.Ellipsis} to={href(toPage + 1)} relative={relative} />)
    }
    if (totalPages - toPage >= 2) {
        items.push(<PageItem key="last" type={totalPages} to={href(totalPages)} relative={relative} />)
    }

    if (toPage < totalPages) {
        items.push(<PageItem key="next" type={PageItemType.Next} to={href(currentPage + 1)} relative={relative} />)
    }

    return <BootstrapPagination children={items} {...props} />
}

export default Pagination
