import { FC, Fragment, ReactElement, MouseEvent, createElement, useState } from 'react'
import { Link, useLoaderData } from 'react-router-dom'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Alert from 'react-bootstrap/Alert'
import Collapse from 'react-bootstrap/Collapse'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMobileScreen, faBookmark } from '@fortawesome/free-solid-svg-icons'
import { AuthenticatedLoaderFunction } from '../lib/AuthProvider'
import api from '../lib/Api'
import Ticket from '../components/Ticket'
import CardName from '../components/CardName'
import { CardInfo } from './account/PaymentCardsPage'
import { TicketMedium } from './account/MobileDevicesPage'
import { TicketInfo, isActivatedTicket } from '../lib/Interfaces'
import moment from 'moment'
import 'moment/locale/pl'

interface CardInfoWithTickets extends CardInfo {
    tickets: TicketInfo[]
}

interface TicketMediumWithTickets extends TicketMedium {
    tickets: TicketInfo[]
}

export interface BalanceInfo {
    accountBalance: number
    serverTime: string
}

export interface LoaderData {
    balance: BalanceInfo
    paymentCards: CardInfoWithTickets[]
    ticketMediums: TicketMediumWithTickets[]
}

export const ticketsLoader: AuthenticatedLoaderFunction = async ({ request, token }): Promise<LoaderData> => {
    const balance = await api
        .get('/api/balance')
        .then((r): BalanceInfo => r.data)
        .catch(
            (err): BalanceInfo => ({
                accountBalance: 0,
                serverTime: new Date().toISOString(),
            })
        )

    const paymentCards = await api
        .get('/api/cards')
        .then(
            async r =>
                await Promise.all(
                    r.data.cards.map(async (card: CardInfo): Promise<CardInfoWithTickets> => {
                        const tickets = card.hash
                            ? await api
                                  .get(`/api/cards/tickets?hash=${card.hash}&psn=${card.psn}`)
                                  .then((r): TicketInfo[] => r.data.tickets)
                                  .catch((err): TicketInfo[] => [])
                            : ([] as TicketInfo[])

                        return { ...card, tickets }
                    })
                )
        )
        .catch(err => [])

    const ticketMediums = await api
        .get('/api/ticket-mediums')
        .then(
            async r =>
                await Promise.all(
                    r.data.ticketMediums.map(async (ticketMedium: TicketMedium): Promise<TicketMediumWithTickets> => {
                        const tickets = await api
                            .get(`/api/ticket-mediums/${ticketMedium.ticketMediumId}`)
                            .then((r): TicketInfo[] => r.data.tickets)
                            .catch((err): TicketInfo[] => [])

                        return { ...ticketMedium, tickets }
                    })
                )
        )
        .catch(err => [])

    return { balance, paymentCards, ticketMediums }
}

const TicketsPage: FC = (): ReactElement => {
    const { paymentCards, ticketMediums } = useLoaderData() as LoaderData

    const paymentCardsListView =
        paymentCards.length > 0 ? (
            createElement(
                Fragment,
                null,
                paymentCards.map(card => <PaymentCardDetailsView key={card.id} card={card} />)
            )
        ) : (
            <Alert variant="light" show={true} className="text-center">
                Niestety nie posiadasz zarejestrowanych kart płatniczych, na które można kupić bilet. Dodaj{' '}
                <Link to="/your-account/cards">kartę</Link> w swoim profilu.
            </Alert>
        )

    const ticketMediumsListView =
        ticketMediums.length > 0
            ? createElement(
                  Fragment,
                  null,
                  ticketMediums.map(ticketMedium => (
                      <TicketMediumDetailsView key={ticketMedium.ticketMediumId} ticketMedium={ticketMedium} />
                  ))
              )
            : null

    return (
        <div className="p-5">
            <h1 className="h4 mb-3">Moje bilety</h1>

            {paymentCardsListView}

            {ticketMediumsListView}
        </div>
    )
}

const PaymentCardDetailsView: FC<{ card: CardInfoWithTickets }> = ({
    card,
}: {
    card: CardInfoWithTickets
}): ReactElement =>
    card.hash ? (
        <>
            <h2 className="h5 mt-2">
                <CardName card={card} />
            </h2>
            <TicketListView tickets={card.tickets} />
        </>
    ) : (
        <>
            <h2 className="h5 mt-2">
                <CardName card={card} />
            </h2>
            <Alert variant="light" show={true} className="text-center">
                Zakup biletów na tę kartę będzie możliwy po zakończeniu weryfikacji.
            </Alert>
        </>
    )

const TicketMediumDetailsView: FC<{ ticketMedium: TicketMediumWithTickets }> = ({
    ticketMedium,
}: {
    ticketMedium: TicketMediumWithTickets
}): ReactElement =>
    ticketMedium.tickets.length ? (
        <>
            <h2 className="h5 mt-2">
                <FontAwesomeIcon icon={faMobileScreen} fixedWidth={true} /> {ticketMedium.name}
            </h2>
            <TicketListView
                tickets={ticketMedium.tickets}
                emptyMessage={`Brak ważnych biletów na urządzeniu ${ticketMedium.name}`}
            />
        </>
    ) : (
        <></>
    )

interface TicketListViewProps {
    tickets: TicketInfo[]
    emptyMessage?: string
}

const TicketListView: FC<TicketListViewProps> = ({ tickets, emptyMessage }: TicketListViewProps): ReactElement => {
    const [collapsed, setCollapsed] = useState(true)

    const toggleCollapse = (e: MouseEvent<HTMLElement>) => {
        e.preventDefault()

        setCollapsed(!collapsed)
    }

    const ticketsAggregated = (tickets || []).reduce(
        (result, ticket) => {
            let type = 'bought' as 'active' | 'bought' | 'expired'
            if (isActivatedTicket(ticket)) {
                const activeFrom = moment(ticket.activeFrom)
                const activeTo = moment(ticket.activeTo)
                const expirationTime = moment(ticket.expirationTime ?? ticket.activeTo)
                const currentTime = moment()

                if (activeFrom.isSameOrBefore(currentTime) && activeTo.isSameOrAfter(currentTime)) {
                    type = 'active'
                } else if (activeTo.isBefore(currentTime) && expirationTime.isSameOrAfter(currentTime)) {
                    // Bilety, które straciły ważność, ale jeszcze przez krótki czas pokazujemy
                    // w grupie Aktywowane
                    type = 'active'
                } else if (activeTo.isBefore(currentTime)) {
                    type = 'expired'
                }
            }

            result[type].push(ticket)

            return result
        },
        {
            active: [] as TicketInfo[],
            bought: [] as TicketInfo[],
            expired: [] as TicketInfo[],
        }
    )

    const listView = (items: TicketInfo[], props: Record<string, unknown> = {}): ReactElement => {
        if (!items.length) {
            return <></>
        }

        let className = 'g-4 mb-4'
        if ('className' in props && props.className) {
            className = `${props.className} ${className}`
        }

        return createElement(
            Row,
            { ...props, xs: 1, sm: 2, md: 3, lg: 4, className },
            items.map((ticket: TicketInfo, idx) => (
                <Col key={`${idx}-${ticket.ticketTypeId}`}>
                    <Ticket ticket={ticket} />
                </Col>
            ))
        )
    }

    return (
        <div className="ps-4 pt-2">
            <h3 className="h5 mb-2">
                <FontAwesomeIcon icon={faBookmark} className="ticket-ribbon-active" /> Aktywowane bilety (
                {ticketsAggregated.active.length})
            </h3>
            {!!ticketsAggregated.active.length ? (
                listView(ticketsAggregated.active)
            ) : (
                <Alert variant="light" show={true} className="text-center">
                    Nie posiadasz aktywnego biletu
                </Alert>
            )}

            {!!ticketsAggregated.bought.length && (
                <>
                    <h3 className="h5 mb-2">
                        <FontAwesomeIcon icon={faBookmark} className="ticket-ribbon-bought" /> Kupione bilety (
                        {ticketsAggregated.bought.length})
                    </h3>
                    {listView(ticketsAggregated.bought)}
                </>
            )}

            {!!ticketsAggregated.expired.length && (
                <>
                    <h3 className="h5 mb-2">
                        <FontAwesomeIcon icon={faBookmark} className="ticket-ribbon-expired" /> Nieważne bilety (
                        {ticketsAggregated.expired.length}){' '}
                        <button className="badge btn btn-link text-decoration-none text-muted" onClick={toggleCollapse}>
                            {collapsed ? 'Pokaż' : 'Ukryj'}
                        </button>
                    </h3>

                    <Collapse in={!collapsed}>{listView(ticketsAggregated.expired)}</Collapse>
                </>
            )}
        </div>
    )
}

export default TicketsPage
