import { FC, ReactElement, MouseEvent, useState, useEffect } from 'react'
import {
    Link,
    Navigate,
    Outlet,
    LoaderFunction,
    ActionFunction,
    useLoaderData,
    useNavigate,
    useSubmit,
    useParams,
    useSearchParams,
    useRevalidator,
    redirect,
} from 'react-router-dom'
import { Passenger } from './PassengersPage'
import api from '../../lib/Api'
import Form, { SelfValidatingForm, useFormValues, useFormErrors } from '../../lib/Form'
import CardName from '../../components/CardName'
import { isMobile } from 'react-device-detect'
import Table from 'react-bootstrap/Table'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import Spinner from 'react-bootstrap/Spinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faUserPlus, faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import 'moment/locale/pl'

export interface CardInfo {
    id: string // Identyfikator transakcji z procesu rejestracji karty
    hash: string | null // Karty bez tokenu to takie, które są w trakcie weryfikacji
    maskedPan: string | null
    psn: number | null
    par: string | null
    cardType: string | null
    createDate: string
    passengerId: number
}

interface ModalWindowParams {
    card: CardInfo
    handleClose: () => void
}

type ModalType = 'delete' | undefined

export const paymentCardsLoader: LoaderFunction = async () => {
    return await api.get('/api/cards').then(r => r.data.cards)
}

export const paymentCardsAction: ActionFunction = async ({ request }) => {
    switch (request.method) {
        case 'POST': {
            const formData = await request.formData()
            const {
                data: { payPageUrl },
            } = await api.post<{ payPageUrl: string }>('/api/cards/session-token', {
                passengerId: Number(formData.get('passenger_id')),
                landingPage: new URL('/your-account/cards/landing/{transactionId}', document.baseURI).href,
                userDevice: isMobile ? 'mobile' : 'desktop',
            })

            return redirect(payPageUrl)
        }
    }

    return null
}

export const AddPaymentCardPage: FC = (): ReactElement => {
    const passengers = useLoaderData() as Passenger[]
    const { passengerId } = useParams()
    const [urlParams] = useSearchParams()
    const passenger = passengers.find(passenger => passenger.id === parseInt(passengerId as string)) ?? null

    type RegistrationMethod =
        | 'gate' // Rejestracja karty przez bramkę płatniczą eService
        | 'code' // Rejestracja karty przez kod wpisany na biletomacie
    const registrationMethod: RegistrationMethod = urlParams.get('method') === 'gate' ? 'gate' : 'code'
    const defaultRegistrationMethod: RegistrationMethod = 'code'

    type RegistrationCode = {
        registrationCode: string
        createDate: moment.Moment
        expirationTime: moment.Moment
        canBeRenewed: boolean
    }

    const [values, onChange] = useFormValues({
        passenger_id: passengers.length > 0 && passengers[0].id ? passengers[0].id.toString() : 'new',
    })

    const [registrationCode, setRegistrationCode] = useState(undefined as RegistrationCode | null | undefined)

    const navigate = useNavigate()
    const submit = useSubmit()

    const handleCancel = () => navigate(-1)

    const handleSelectPassenger = async (formData: FormData) => {
        const passengerId = formData.get('passenger_id')

        if (passengerId === 'new') {
            navigate('/your-account/passengers/add', { state: { successUrl: '/your-account/cards/add/:passengerId' } })
        } else {
            navigate(
                './' +
                    passengerId +
                    (registrationMethod !== defaultRegistrationMethod ? `?method=${registrationMethod}` : '')
            )
        }
    }

    useEffect(() => {
        if (!passenger || !passenger.id) {
            return
        }

        if (registrationMethod === 'code') {
            const fetchRegistrationCode = async (passengerId: number) => {
                try {
                    const { data } = await api.post<RegistrationCode>('/api/cards/registration-code', { passengerId })
                    setRegistrationCode({
                        ...data,
                        createDate: moment(data.expirationTime),
                        expirationTime: moment(data.expirationTime),
                    })
                } catch (error) {
                    setRegistrationCode(null)
                }
            }

            fetchRegistrationCode(passenger.id).catch(console.error)
        }
    }, [passenger, registrationMethod])

    if (passenger) {
        if (registrationMethod === 'code') {
            const handleRenewCode = async (e: MouseEvent<HTMLButtonElement>) => {
                e.preventDefault()

                e.currentTarget.disabled = true

                try {
                    const { data } = await api.patch<RegistrationCode>('/api/cards/registration-code', {
                        passengerId: passenger.id,
                    })
                    setRegistrationCode({
                        ...data,
                        createDate: moment(data.expirationTime),
                        expirationTime: moment(data.expirationTime),
                    })
                } catch (error) {
                    setRegistrationCode(null)
                }

                if (e.currentTarget) {
                    e.currentTarget.disabled = false
                }
            }
            return (
                <>
                    <h1 className="h4 mb-3">Rejestracja nowej karty</h1>

                    <p>
                        Dodajesz kartę dla pasażera: <strong>{passenger.customName}</strong>
                    </p>

                    {registrationCode ? (
                        <>
                            <p>
                                Wybierz na ekranie biletomatu opcję rejestacji karty, a następnie wprowadź poniższy kod:
                            </p>
                            <p>
                                <strong className="h4">{registrationCode.registrationCode}</strong>
                            </p>
                            <p>
                                Kod jest ważny do {registrationCode.expirationTime.format('YYYY-MM-DD HH:mm:ss')}.{' '}
                                {registrationCode.canBeRenewed ? (
                                    <button className="btn btn-link p-0 text-decoration-none" onClick={handleRenewCode}>
                                        Kliknij tutaj, aby przedłużyć ważność kodu.
                                    </button>
                                ) : (
                                    <>Po tym terminie konieczne będzie wygenerowanie nowego kodu.</>
                                )}
                            </p>
                        </>
                    ) : registrationCode === null ? (
                        <Alert variant="light" className="text-center">
                            Nie udało się wygenerować kodu do rejestracji karty. Spróbuj ponownie za chwilę.
                        </Alert>
                    ) : null}

                    <div className="d-block text-end mt-3">
                        <Link className="btn btn-secondary" role="button" to="/your-account/cards">
                            <FontAwesomeIcon icon={faAngleLeft} fixedWidth={true} /> Powrót do listy kart
                        </Link>
                    </div>
                </>
            )
        } else if (registrationMethod === 'gate') {
            const handleAddCard = (passenger: Passenger) => async () => {
                if (!passenger.id) {
                    navigate('/your-account/cards')
                    return
                }

                submit(
                    { passenger_id: passenger.id.toString() },
                    {
                        method: 'post',
                        action: '/your-account/cards',
                        encType: 'application/x-www-form-urlencoded',
                    }
                )
            }

            return (
                <>
                    <h1 className="h4 mb-3">Rejestracja nowej karty</h1>

                    <p>
                        Dodajesz kartę dla pasażera: <strong>{passenger.customName}</strong>
                    </p>

                    <p className="small">
                        Za chwilę zostaniesz przekierowany na stronę operatora płatności w celu wprowadzenia dancyh
                        karty. Z Twojego konta nie zostaną pobrane żadne środki - kwota transakcji to 0 zł.
                    </p>

                    <div className="d-block text-end mt-3">
                        <Button variant="secondary" type="button" onClick={handleCancel}>
                            <FontAwesomeIcon icon={faAngleLeft} fixedWidth={true} /> Powrót{' '}
                        </Button>{' '}
                        <Button variant="primary" type="button" onClick={handleAddCard(passenger)}>
                            Dalej <FontAwesomeIcon icon={faAngleRight} fixedWidth={true} />
                        </Button>
                    </div>
                </>
            )
        } else {
            return (
                <>
                    <h1 className="h4 mb-3">Rejestracja nowej karty</h1>
                    <Alert variant="danger" className="text-center">
                        Nieobsługiwana metoda dodawania karty
                    </Alert>
                </>
            )
        }
    }

    return (
        <>
            <h1 className="h4 mb-3">Rejestracja nowej karty</h1>

            <SelfValidatingForm method="post" onSubmit={handleSelectPassenger}>
                {passengers.length ? (
                    <>
                        <p className="small">Wybierz konto pasażera, dla którego dodajesz kartę:</p>

                        {passengers.map(passenger => (
                            <Form.Check
                                type="radio"
                                name="passenger_id"
                                value={passenger.id?.toString()}
                                label={passenger.customName}
                                checked={values.passenger_id === passenger.id?.toString()}
                                onChange={onChange}
                                key={passenger.id?.toString()}
                                id={passenger.id?.toString()}
                            />
                        ))}
                        <Form.Check
                            type="radio"
                            name="passenger_id"
                            value="new"
                            label="dodaj nowe konto"
                            checked={values.passenger_id === 'new'}
                            onChange={onChange}
                            key="new"
                            id="new"
                        />

                        <div className="d-block text-end mt-3">
                            <Button variant="secondary" type="button" onClick={handleCancel}>
                                <FontAwesomeIcon icon={faAngleLeft} fixedWidth={true} /> Powrót
                            </Button>{' '}
                            <Button variant="primary" type="submit">
                                Dalej <FontAwesomeIcon icon={faAngleRight} fixedWidth={true} />
                            </Button>
                        </div>
                    </>
                ) : (
                    <>
                        <input type="hidden" name="passenger_id" value="new" />
                        <p className="small">
                            Aby móc kontynuować, należy wprowadzić dane pasażera, który będzie korzystał z karty.
                        </p>
                        <div className="d-block text-end">
                            <Button variant="secondary" type="button" onClick={handleCancel}>
                                <FontAwesomeIcon icon={faAngleLeft} fixedWidth={true} /> Powrót
                            </Button>{' '}
                            <Button variant="primary" type="submit">
                                <FontAwesomeIcon icon={faUserPlus} fixedWidth={true} /> Dodaj pasażera
                            </Button>
                        </div>
                    </>
                )}
            </SelfValidatingForm>
        </>
    )
}

const DeletePaymentCard: FC<ModalWindowParams> = ({ card, handleClose }): ReactElement => {
    const [errors, setErrors] = useFormErrors()

    const revalidator = useRevalidator()

    const handleSubmit = async (formData: FormData) => {
        const cardId = card.id
        try {
            // Usuwanie karty
            const {
                data: { success },
            } = await api.delete<{ success: boolean }>(`/api/cards/${cardId}`)

            if (!success) {
                throw new Error()
            }

            // Pobieramy na nowo listę urządzeń
            revalidator.revalidate()

            handleClose()
        } catch (error) {
            setErrors({
                message: 'Nie udało się usunąć karty. Spróbuj ponownie za chwilę.',
            })
        }
    }

    return (
        <Modal show={true} onHide={handleClose} size="sm" centered>
            <Modal.Header closeButton>
                <Modal.Title>Usuwanie karty</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {errors.message && (
                    <Alert variant="danger" className="text-center">
                        {errors.message}
                    </Alert>
                )}
                <SelfValidatingForm method="delete" dispatchError={setErrors} onSubmit={handleSubmit}>
                    <input type="hidden" name="card_id" value={card.id} />

                    <p className="small">Czy na pewno chcesz usunąć kartę ze swojego konta?</p>

                    <p className="small">
                        Nadal będziesz mógł korzystać z biletów jeśli są zakodowane na karcie, jednak nie będziesz już
                        ich widzieć na swoim koncie.
                    </p>

                    <div className="d-block text-end">
                        <Button variant="secondary" type="button" onClick={handleClose}>
                            Anuluj
                        </Button>{' '}
                        <Button variant="primary" type="submit">
                            Usuń kartę
                        </Button>
                    </div>
                </SelfValidatingForm>
            </Modal.Body>
        </Modal>
    )
}

export const PaymentCardLandingPage: FC = () => {
    const { transactionId } = useParams()
    const [isPatched, setPatched] = useState<boolean>(false)

    useEffect(() => {
        // Oznaczamy transakcję jako zakończoną
        const patch = async (transactionId: string) => {
            await api.patch<{ success: boolean }>(`/api/cards/session-token/${transactionId}`)
            setPatched(true)
        }
        if (transactionId) {
            patch(transactionId)
        }
    }, [transactionId])

    return isPatched ? <Navigate to=".." /> : null
}

const PaymentCardsPage: FC = (): ReactElement => {
    const cards = useLoaderData() as CardInfo[]
    const isLandingMsgVisible = cards.filter(card => !card.hash).length > 0

    const [currentModal, setCurrentModal] = useState<{ type: ModalType; card?: CardInfo }>({
        type: undefined,
        card: undefined,
    })

    const navigate = useNavigate()

    const handleRedirect = (url: string) => (e: MouseEvent<HTMLElement>) => {
        e.preventDefault()
        navigate(url, { replace: false })
    }

    const handleAction = (type: ModalType, card: CardInfo) => (e: MouseEvent<HTMLElement>) => {
        e.preventDefault()
        setCurrentModal({ type, card })
    }

    const handleClose = () => setCurrentModal({ type: undefined, card: undefined })

    let modal: ReactElement | null = null
    if (currentModal.card && currentModal.type === 'delete') {
        modal = <DeletePaymentCard card={currentModal.card} handleClose={handleClose} />
    }

    return (
        <>
            <h1 className="h4 mb-3">Karty płatnicze</h1>

            <Outlet />
            <Alert variant="info" show={isLandingMsgVisible} className="text-center">
                Karta została dodana. Oczekujemy na potwierdzenie danych od operatora płatności.
            </Alert>

            {modal}

            {cards.length ? (
                <>
                    <h2 className="h5 mb-3">Zarejestrowane karty</h2>
                    <Table responsive hover>
                        <thead>
                            <tr>
                                <th scope="col">Numer</th>
                                <th scope="col" className="text-center">
                                    Data rejestracji
                                </th>
                                <th scope="col" className="text-end"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {cards.map(card => (
                                <tr key={card.id}>
                                    <td>
                                        {card.hash ? (
                                            <>
                                                <CardName card={card} />
                                            </>
                                        ) : (
                                            <>
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />{' '}
                                                W trakcie weryfikacji...
                                            </>
                                        )}
                                    </td>
                                    <td className="text-center">{new Date(card.createDate).toLocaleDateString()}</td>
                                    <td width="40" className="text-end">
                                        <button
                                            className="btn btn-link p-0 text-decoration-none text-muted"
                                            title="Usuń"
                                            aria-label="Usuń"
                                            onClick={handleAction('delete', card)}>
                                            <FontAwesomeIcon icon={faTrashAlt} fixedWidth={true} />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </>
            ) : (
                <>
                    <p className="mb-3">Obecnie nie masz przypisanych kart do swojego konta.</p>
                    <p>Dodanie karty pozwoli Ci na:</p>
                    <ul>
                        <li>sprawdzanie historii swoich przejazdów i pobranych opłat,</li>
                        <li>zakup i korzystanie z biletów okresowych zakodowanych na karcie płatniczej.</li>
                    </ul>
                </>
            )}

            <Button variant="primary" onClick={handleRedirect('./add')}>
                {cards.length === 0 ? 'Dodaj kartę' : 'Dodaj następną'}
            </Button>
        </>
    )
}

export default PaymentCardsPage
