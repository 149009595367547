import { FC, ReactElement } from 'react'

const TermsOfServicePage: FC = (): ReactElement => {
    return (
        <div className="p-5">
            <h1 className="h4 mb-3">
                Regulamin korzystania z Elektronicznego sklepu e-bilet wraz z kontem użytkownika oraz aplikacji mobilnej
            </h1>
            <h2 className="h5 mb-3 text-center">§ 1 Postanowienia ogólne</h2>
            <ol type="1">
                <li>
                    „Regulamin korzystania z Elektronicznego sklepu e-bilet wraz z kontem użytkownika oraz aplikacji
                    mobilnej” określa zasady korzystania przez Użytkowników oraz Użytkowników spersonalizowanych ze
                    sklepu e-bilet oraz aplikacji mobilnej.
                </li>
                <li>
                    „Regulamin korzystania z Elektronicznego sklepu e-bilet wraz z kontem użytkownika oraz aplikacji
                    mobilnej” dostępny jest:
                    <ol type="a">
                        <li>
                            w formie elektronicznej w portalu internetowym (
                            <a href="https://kartabankowa.erzeszow.pl/" target="_blank" rel="noopener noreferrer">
                                kartabankowa.erzeszow.pl
                            </a>
                            ) i w aplikacji mobilnej (E-bilet Rzeszów);
                        </li>
                        <li>w postaci papierowej w Punktach Obsługi Podróżnego.</li>
                    </ol>
                </li>
                <li>
                    Podmiotem świadczącym usługi jest Zarząd Transportu Miejskiego w Rzeszowie z siedzibą przy ul.
                    Trembeckiego 3, 35-234 Rzeszów, numer NIP: 517-03-04-196.
                </li>
            </ol>
            <h2 className="h5 mb-3 text-center">§ 2 Definicje</h2>
            <p>Wskazanym poniżej pojęciom nadaje się następujące znaczenie:</p>
            <ol type="1">
                <li>
                    Administrator - osoba uprawniona do zarządzania sklepem e-bilet oraz aplikacją mobilną lub
                    parametrami ich pracy z wykorzystaniem przeznaczonych do tego narzędzi;
                </li>
                <li>
                    Aplikacja mobilna - aplikacja dedykowana urządzeniom mobilnym z systemem operacyjnym iOS lub
                    Android, dostępna do pobrania bezpłatnie na platformach dystrybucji cyfrowej App Store i Sklep Play
                    (Google Play). Aplikacja umożliwiająca Użytkownikowi spersonalizowanemu korzystanie ze sklepu
                    e-bilet;
                </li>
                <li>
                    Kanały e-commerce - płatności wykonywane z wykorzystaniem instrumentów płatniczych noszących
                    oznaczenia Visa, Visa Electron, VPay, MasterCard, MasterCard Electronic, Maestro, MasterCard World,
                    MasterCard World Signia, a także za pomocą BLIK i przelewów bankowych; z wyłączeniem płatności w
                    terminalach płatniczych;
                </li>
                <li>
                    Karta płatnicza - karta płatnicza w rozumieniu art. 2 pkt 15a Ustawy z dnia 19 sierpnia 2011 r. o
                    usługach płatniczych (t.j. Dz. U. z 2022 r. poz. 2360; zm.: Dz. U. z 2022 r. poz. 2640.), oparta o
                    mikroprocesor w standardzie EMV;
                </li>
                <li>
                    Konto użytkownika - elektroniczne konto zakładane w sklepie e-bilet za pośrednictwem interfejsu
                    webowego lub Aplikacji mobilnej, dodanie danych osobowych Użytkownika, , przeglądanie historii
                    operacji, historii przejazdów, dokonywanie zakupów biletów okresowych;
                </li>
                <li>
                    NFC - krótkozasięgowy, wysokoczęstotliwościowy, radiowy standard komunikacji pozwalający na
                    bezprzewodową wymianę danych na odległość do 20 centymetrów;
                </li>
                <li>
                    Operator - samorządowy zakład budżetowy oraz przedsiębiorca uprawniony do prowadzenia działalności
                    gospodarczej w zakresie przewozu osób, który zawarł z Organizatorem umowę o świadczenie usług w
                    zakresie publicznego transportu zbiorowego;
                </li>
                <li>
                    Organizator - właściwa Jednostka samorządu terytorialnego zapewniający funkcjonowanie publicznego
                    transportu zbiorowego na danym obszarze;
                </li>
                <li>
                    Portal sklepu e-bilet - portal internetowy dedykowany Użytkownikom oraz , umożliwiający zarządzanie
                    kontem Użytkownika i zakup biletu, dostępny pod adresem internetowym{' '}
                    <a href="https://kartabankowa.erzeszow.pl/" target="_blank" rel="noopener noreferrer">
                        kartabankowa.erzeszow.pl
                    </a>
                    ;
                </li>
                <li>
                    Przewoźnik - przedsiębiorca uprawniony do prowadzenia działalności gospodarczej w zakresie przewozu
                    osób na podstawie certyfikatu kompetencji zawodowych w zakresie przewozu osób;
                </li>
                <li>
                    Publiczny transport zbiorowy (PTZ) - powszechnie dostępny regularny przewóz osób wykonywany w
                    określonych odstępach czasu i po określonej linii komunikacyjnej, liniach komunikacyjnych lub sieci
                    komunikacyjnej, świadczony na zlecenie Gminy Miasto Rzeszów – Zarządu Transportu Miejskiego w
                    Rzeszowie;
                </li>
                <li>
                    Regulamin - „Regulamin korzystania z Elektronicznego sklepu e-bilet wraz z kontem użytkownika oraz
                    aplikacji mobilnej”;
                </li>
                <li>
                    RODO - Rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w
                    sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego
                    przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych)
                    (Dz. U. UE. L. Nr 119 z 04-05-2016 r.);
                </li>
                <li>
                    Zasady ochrony prywatności - dokument regulujący Politykę Prywatności dostępny jest na stronie{' '}
                    <a href="https://ztm.rzeszow.pl/rodo/" target="_blank" rel="noopener noreferrer">
                        https://ztm.rzeszow.pl/rodo/
                    </a>
                </li>
                <li>
                    Taryfa - wyciąg identyfikujący cennik i zakres usług przewozu osób w Publicznym transporcie
                    zbiorowym;
                </li>
                <li>
                    Token - bezpieczna wartość generowana na podstawie danych wrażliwych identyfikatora,
                    uniemożliwiająca odtworzenie danych źródłowych, na podstawie których został wygenerowany;
                </li>
                <li>
                    Ulga - zniżka na przejazd, którą reguluje ustawa lub udziela jej Jednostka samorządu terytorialnego.
                    Zniżka ta może obejmować swoim oddziaływaniem określone grupy pasażerów na podstawie okazania na
                    wezwanie organów kontrolnych dokumentów uprawniających do ulgi;
                </li>
                <li>
                    Urządzenie mobilne - urządzenie przenośne pozwalające na instalację i korzystanie z Aplikacji
                    mobilnej lub z przeglądarką internetową umożliwiającą korzystanie ze sklepu e-bilet;
                </li>
                <li>
                    Użytkownik - osoba fizyczna nieposiadająca konta w sklepie e-bilet ani aplikacji mobilnej,
                    uiszczająca opłatę za usługę PTZ przy wykorzystaniu kasownika z dedykowaną funkcjonalnością, karty
                    płatniczej, biletomatu, kupionego uprzednio biletu w wyznaczonym punkcie lub z innego źródła
                    pozwalającego na zakup biletów;
                </li>
                <li>
                    Operator płatności - podmiot, który przyjmuje i rozlicza transakcje realizowane w systemie sklepu
                    e-bilet lub aplikacji mobilnej.
                </li>
            </ol>
            <h2 className="h5 mb-3 text-center">§ 3 Tworzenie Konta użytkownika</h2>
            <ol type="1">
                <li>
                    Utworzenie Konta użytkownika możliwe jest poprzez
                    <ol type="a">
                        <li>Aplikację mobilną</li>
                        <li>Portal sklepu e-bilet</li>
                    </ol>
                </li>
                <li>
                    Wnioskodawca, tworząc Konto użytkownika w systemie sklepu e-bilet zobowiązany jest do zapoznania się
                    i zaakceptowania Regulaminu korzystania z Elektronicznego sklepu e-bilet wraz z kontem użytkownika
                    oraz aplikacji mobilnej.
                </li>
                <li>
                    Zakładanie Konta użytkownika wymaga podania danych, które umożliwią zalogowanie się do sklepu
                    e-bilet.
                </li>
                <li>
                    Dane potrzebne do utworzenia Konta użytkownika muszą być prawdziwe i zgodne ze stanem faktycznym.
                </li>
                <li>
                    Dane wymagane do utworzenia Konta użytkownika w sklepie e-bilet obejmują:
                    <ol type="a">
                        <li>imię i nazwisko (obowiązkowe);</li>
                        <li>dane adresowe</li>
                        <li>numer telefonu (opcjonalnie);</li>
                        <li>
                            adres e-mail. Adres e-mail pozwala na przesyłanie pierwszego hasła do zalogowania do sklepu
                            e-bilet, aktywacji Konta użytkownika, resetu hasła i potwierdzania tożsamości Użytkownika.
                        </li>
                    </ol>
                </li>
                <li>
                    Wnioskodawca odpowiada za zgodność ze stanem faktycznym danych wprowadzonych podczas tworzenia Konta
                    użytkownika;
                </li>
                <li>
                    Konto w sklepie e-bilet może założyć:
                    <ol type="a">
                        <li>osoba fizyczna o pełnej zdolności do czynności prawnych;</li>
                        <li>
                            osoba o ograniczonej zdolności do czynności prawnych (np. osoba małoletnia mająca, co
                            najmniej 13 lat albo osoba znajdująca się pod kuratelą w rozumieniu art. 16 § 2 Kodeksu
                            cywilnego);
                        </li>
                        <li>
                            rodzic w imieniu dziecka znajdującego się pod jego władzą rodzicielską (z zastrzeżeniem § 3
                            ust. 8 lit. b), przy czym konto dziecka nieposiadającego zdolności do czynności prawnych
                            jest zawsze powiązane z kontem rodzica;
                        </li>
                        <li>opiekun w imieniu osoby, nad którą sprawuje opiekę;</li>
                    </ol>
                </li>
            </ol>
            <h2 className="h5 mb-3 text-center">§ 4 Zmiana i modyfikacja danych</h2>
            <ol type="1">
                <li>
                    Użytkownik ma możliwość zmiany i modyfikacji danych, o których mowa w § 3, zawartych w Koncie
                    użytkownika.
                </li>
                <li>
                    Zmiany można dokonać w Aplikacji mobilnej lub korzystając ze strony internetowej sklepu e-bilet (
                    <a href="https://kartabankowa.erzeszow.pl/" target="_blank" rel="noopener noreferrer">
                        kartabankowa.erzeszow.pl
                    </a>
                    )
                </li>
            </ol>
            <h2 className="h5 mb-3 text-center">§ 5 Logowanie do Konta użytkownika i zasady korzystania</h2>
            <ol type="1">
                <li>
                    Sposób logowania się oraz zarządzania Kontem użytkownika dostępny jest na stronie{' '}
                    <a href="https://kartabankowa.erzeszow.pl/" target="_blank" rel="noopener noreferrer">
                        kartabankowa.erzeszow.pl
                    </a>
                </li>
                <li>
                    Użytkownik będzie korzystał z Konta użytkownika w sposób niepowodujący zagrożeń bezpieczeństwa
                    zawartych w nim danych (z wyjątkiem danych, które Użytkownik może swobodnie modyfikować za zgodą
                    Usługodawcy).
                </li>
                <li>
                    Konto użytkownika udostępniane jest Użytkownikowi przez Usługodawcę bezpłatnie, codziennie przez
                    całą dobę, z zastrzeżeniem sytuacji, kiedy sklep e-bilet nie działa z powodu awarii lub konserwacji.
                </li>
                <li>
                    Usługodawca uprawniony jest do dochodzenia odpowiedzialności odszkodowawczej od Użytkownika
                    spersonalizowanego w przypadku próby niedozwolonej ingerencji, uszkodzenia lub zakłócenia pracy
                    sklepu e-bilet.
                </li>
                <li>
                    Użytkownik zobowiązany jest do potwierdzenia przysługującej mu ulgi lub uprawnienia do przejazdów
                    bezpłatnych poprzez posiadanie podczas kontroli w środku przewozu dokumentu potwierdzającego
                    uprawnienie do ulgi lub przejazdów bezpłatnych.
                </li>
            </ol>
            <h2 className="h5 mb-3 text-center">§ 6 Rodzaje Konta użytkownika</h2>
            <ol type="1">
                <li>
                    W ramach sklepu e-bilet możliwe jest tworzenie Konta użytkownika:
                    <ol type="a">
                        <li>jednoosobowego - osoby fizycznej,</li>
                        <li>
                            konta grupującego na wniosek Użytkownika konta jednoosobowego, może ono zostać rozszerzone
                            przez dopisanie do niego innych osób (w szczególności nieposiadających zdolności do
                            czynności prawnych, z ograniczoną zdolnością do czynności prawnych, innych osób
                            nieposiadających Konta użytkownika).
                        </li>
                    </ol>
                </li>
            </ol>
            <h2 className="h5 mb-3 text-center">§ 7 Bilety</h2>
            <ol type="1">
                <li>Sposób nabycia biletów oraz taryfy regulują odrębne przepisy Organizatora.</li>
                <li>
                    Zakup biletu jest możliwy tylko w przypadku posiadania odpowiedniej kwoty środków pieniężnych
                    potrzebnych do zapłaty ceny nabywanego biletu.
                </li>
            </ol>
            <h2 className="h5 mb-3 text-center">§ 8 Aplikacja mobilna</h2>
            <ol type="1">
                <li>Aplikacja mobilna zawiera następujące funkcjonalności:</li>
                <ol type="a">
                    <li>zarządzanie Kontem użytkownika;</li>
                    <li>uiszczanie opłat za usługi Publicznego transportu zbiorowego;</li>
                    <li>planowanie podróży według kryteriów ustalonych przez Użytkownika.</li>
                </ol>
            </ol>
            <h2 className="h5 mb-3 text-center">§ 9 Zwrot opłat z tytułu niewykorzystanych biletów</h2>
            <ol type="1">
                <li>
                    Zwrot niewykorzystanych, zakupionych biletów realizowany jest zgodnie z regulaminem Organizatora
                    przelewem lub w POP.
                </li>
            </ol>
            <h2 className="h5 mb-3 text-center">§ 10 Wydawanie faktur</h2>
            <ol type="1">
                <li>
                    Użytkownik może złożyć wniosek o wystawienie faktury z tytułu zakupionych biletów na portalu (
                    <a href="https://kartabankowa.erzeszow.pl/" target="_blank" rel="noopener noreferrer">
                        kartabankowa.erzeszow.pl
                    </a>
                    ) po zalogowaniu na konto 2. Faktura wystawiana jest do 10 dnia kolejnego miesiąca od nabycia
                    biletu. Po tym terminie na podstawie pisemnego wniosku złożonego przez pasażera.
                </li>
                <li>
                    Faktury będą mogły zostać wystawione w odniesieniu do transakcji sprzedaży, które zostały uznane za
                    prawidłowe.
                </li>
                <li>Faktura zostanie dostarczona w postaci elektronicznej.</li>
            </ol>
            <h2 className="h5 mb-3">§ 11 Reklamacje</h2>
            <ol type="1">
                <li>
                    Użytkownik może za pomocą w POP w ciągu 90 dni od daty transakcji złożyć reklamację, której
                    przedmiotem może być w szczególności:
                    <ol type="a">
                        <li>nieprawidłowego działania Aplikacji mobilnej lub sklepu e-bilet,</li>
                        <li>nieprawidłowego naliczenia opłat z tytułu zakupionych biletów.</li>
                    </ol>
                </li>
                <li>
                    Decyzja Usługodawcy co do wyniku rozpatrzenia wniesionej reklamacji zostanie, według wyboru
                    Użytkownika dostarczone zgodnie z dyspozycją Użytkownika wskazaną we wniosku;
                </li>
                <li>
                    W przypadku nieuwzględnienia lub częściowego uwzględnienia reklamacji Użytkownik lub Użytkownik
                    spersonalizowany w terminie 90 dni od uzyskania informacji co do wyniku rozpatrzenia, może wnieść do
                    Usługodawcy odwołanie.
                </li>
                <li>Odwołanie podlega rozpoznaniu w terminie 30 dni od dnia wpływu.</li>
                <li>Do składania odwołania stosuje się ust. 2 i 3 odpowiednio.</li>
                <li>
                    W przypadku stwierdzenia, iż nieprawidłowe działanie spowodowane jest niewłaściwym użytkowaniem,
                    reklamacja zostanie rozpatrzona negatywnie.
                </li>
                <li>Szczegółowy zakres procesu reklamacji opisany jest w załączniku „Reklamacje i zwroty”.</li>
            </ol>
            <h2 className="h5 mb-3 text-center">§ 12 Wypowiedzenie umowy</h2>
            <ol type="1">
                <li>
                    Użytkownik może wypowiedzieć umowę korzystania ze sklepu e-bilet (co łączy się z zamknięciem konta
                    użytkownika):
                    <ol type="a">
                        <li>za pomocą Aplikacji mobilnej (funkcja zamknięcia konta);</li>
                        <li>
                            za pomocą konta Użytkownika{' '}
                            <a href="https://kartabankowa.erzeszow.pl/" target="_blank" rel="noopener noreferrer">
                                kartabankowa.erzeszow.pl
                            </a>{' '}
                            (funkcja zamknięcia konta),
                        </li>
                        <li>Administrator systemu na wniosek Użytkownika.</li>
                    </ol>
                </li>
                <li>Konto zostanie usunięte wraz z wykonaniem procedury usunięcia.</li>
                <li>
                    Skuteczne wypowiedzenie umowy przez Użytkownika uwarunkowane jest uregulowaniem wszystkich
                    należności.
                </li>
            </ol>
            <h2 className="h5 mb-3 text-center">
                § 13 Wypowiedzenie umowy korzystania ze sklepu e-bilet bądź aplikacji mobilnej przez Usługodawcę
            </h2>
            <ol type="1">
                <li>
                    Usługodawca zastrzega sobie możliwość wypowiedzenia umowy korzystania ze sklepu e-bilet bądź
                    aplikacji mobilnej, ze skutkiem natychmiastowym, jeżeli Użytkownik:
                    <ol type="a">
                        <li>
                            korzysta z funkcjonalności sklepu e-bilet bądź aplikacji mobilnej w sposób niezgodny z jego
                            przeznaczeniem;
                        </li>
                        <li>
                            uszkadza, niszczy lub dokonuje niedozwolonej modyfikacji zasobów informatycznych lub
                            rzeczowych będących w posiadaniu Usługodawcy.
                        </li>
                    </ol>
                </li>
            </ol>
            <h2 className="h5 mb-3 text-center">§ 14 Postanowienia końcowe</h2>
            <ol type="1">
                <li>
                    Usługodawca nie odpowiada za szkody spowodowane niewłaściwym bądź niezgodnym z przeznaczeniem
                    użytkowaniem sklepu e-bilet bądź aplikacji mobilnej
                </li>
                <li>
                    W zakresie nieunormowanym w Regulaminie zastosowanie znajdują przepisy prawa powszechnie
                    obowiązującego, w tym akty prawa miejscowego.
                </li>
                <li>
                    Usługodawca zastrzega możliwość zmiany Regulaminu z ważnych przyczyn obejmujących:
                    <ol type="a">
                        <li>zmiany norm prawnych powszechnie obowiązujących, a mających wpływ na usługi świadczone</li>
                        <li>
                            zmiany lub pojawienie się nowych interpretacji powszechnie obowiązujących przepisów prawa na
                            skutek orzeczeń sądów albo decyzji organów władz publicznych, dotyczących świadczonych usług
                        </li>
                        <li>
                            konieczność dostosowania Regulaminu do rozwiązań technologicznych, udostępnionych usług lub
                            funkcjonalności
                        </li>
                    </ol>
                </li>
                <li>
                    Każda zmiana regulaminu będzie wiązała się z obowiązkiem ponownej jego akceptacji przez Użytkownika
                    lub Użytkownika. Brak akceptacji będzie wiązał się z brakiem możliwości korzystania ze sklepu
                    e-bilet bądź aplikacji mobilnej do momentu jego zaakceptowania.
                </li>
            </ol>
        </div>
    )
}

export default TermsOfServicePage
