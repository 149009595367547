import { FC, ReactElement, useState } from 'react'
import Form, { FormComponentProps, ChangeEventHandler } from '../lib/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'

interface PasswordFieldProps {
    name: string
    value: string
    error?: string
    autoComplete: 'current-password' | 'new-password'
    autoFocus?: boolean
    onChange: ChangeEventHandler
}

export const PasswordField: FC<PasswordFieldProps> = ({
    name,
    value,
    error,
    autoComplete,
    autoFocus,
    onChange,
}): ReactElement => {
    const [isPasswordVisible, setPasswordVisibility] = useState(false)
    const togglePasswordVisibility = () => {
        setPasswordVisibility(!isPasswordVisible)
    }

    return (
        <InputGroup>
            <Form.Control
                type={isPasswordVisible ? 'text' : 'password'}
                name={name}
                autoComplete={autoComplete}
                autoFocus={autoFocus}
                value={value}
                onChange={onChange}
                required
                isInvalid={!!error}
            />
            <InputGroup.Text className="c-pointer" onClick={togglePasswordVisibility}>
                <FontAwesomeIcon icon={isPasswordVisible ? faEyeSlash : faEye} fixedWidth={true} />
            </InputGroup.Text>
            <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
        </InputGroup>
    )
}

const ChangePasswordFields: FC<FormComponentProps> = ({ values, errors, onChange }): ReactElement => {
    return (
        <>
            <Form.Group className="form-group" controlId="password">
                <Form.Label>Hasło</Form.Label>
                <PasswordField
                    name="password"
                    value={values.password as string}
                    error={errors.password}
                    autoComplete="new-password"
                    autoFocus
                    onChange={onChange}
                />
            </Form.Group>

            <Form.Group className="form-group" controlId="password_confirm">
                <Form.Label>Potwierdź hasło</Form.Label>
                <Form.Control
                    type="password"
                    name="password_confirm"
                    autoComplete="new-password"
                    value={values.password_confirm as string}
                    onChange={onChange}
                    required
                    isInvalid={!!errors.password_confirm}
                />
                <Form.Control.Feedback type="invalid">{errors.password_confirm}</Form.Control.Feedback>
            </Form.Group>
        </>
    )
}

export default ChangePasswordFields
