import { FC, ReactElement, useState, useEffect, Dispatch, ChangeEvent } from 'react'
import { FormProps, useSubmit, useActionData } from 'react-router-dom'
import BootsrapForm from 'react-bootstrap/Form'

export type ChangeEventHandler = (e: ChangeEvent<HTMLInputElement>) => void
export type FormValues = Record<string, string | boolean>

export function useFormValues<S extends FormValues>(
    initialValues: S,
    filter: (v: S) => S = (v: S) => v
): [S, ChangeEventHandler, Dispatch<S>] {
    const [values, setValues] = useState(initialValues)

    const onChange: ChangeEventHandler = e => {
        const name = e.target.name

        let value: string | boolean = e.target.value
        if (e.target.type === 'checkbox') {
            value = e.target.checked
        }

        if (name) {
            setValues(prevValues =>
                filter({
                    ...prevValues,
                    [name]: value,
                })
            )
        }
    }

    return [values, onChange, setValues]
}

export type FormErrors = {
    [key: string]: string
} & { clientErrors?: Record<string, string> }

export function useFormErrors(): [FormErrors, Dispatch<FormErrors>] {
    const initialValue = {} as FormErrors
    const [errors, setErrors] = useState(initialValue)

    return [errors ?? initialValue, errors => setErrors(errors)]
}

type SubmitTarget = HTMLFormElement | HTMLButtonElement | HTMLInputElement

type Props = Omit<FormProps, 'onSubmit'> & {
    dispatchError?: Dispatch<FormErrors>
    onSubmit?: (formData: FormData, target: SubmitTarget) => void
}

export interface FormComponentProps {
    values: FormValues
    errors: FormErrors
    onChange: ChangeEventHandler
    setErrors?: Dispatch<FormErrors>
}

// Formularz, który automatycznie waliduje swoje pola przed wysłaniem. Wynik
// walidacji zapisywany jest w atrybucie { clientErrors: ... }
export const SelfValidatingForm: FC<Props> = ({ dispatchError, ...props }: Props): ReactElement => {
    const [validated, setValidated] = useState(false)

    const onSubmit = (e: React.SyntheticEvent<HTMLFormElement, SubmitEvent>): void => {
        if (e.defaultPrevented) {
            return
        }
        e.preventDefault()

        const form = e.currentTarget

        // Kasujemy wyniki walidacji po stronie serwera
        if (dispatchError) dispatchError({} as FormErrors)

        // Walidujemy po stronie klienta - elementy otrzymają
        // pseudoklasy :valid oraz :invalid
        const isValid = form.checkValidity()
        setValidated(true)

        // Pobieramy wynik walidacji po stronie klienta
        const errors = {} as FormErrors
        errors.clientErrors = Array.from(form.elements).reduce(
            (res, el) => {
                const objectEl = el as HTMLObjectElement
                if (objectEl.validity && !objectEl.validity.valid) {
                    res[objectEl.name] = objectEl.validationMessage
                }
                return res
            },
            {} as Record<string, string>
        )

        if (dispatchError && Object.keys(errors.clientErrors).length) {
            dispatchError(errors)
        }

        if (isValid) {
            // Wysyłamy formularz
            if (props.onSubmit) {
                const formData = new FormData(form)

                // Dodajemy do formularza wartość przycisku, który spowodował akcję.
                // Obiekt ten możemy przekazać jako drugi argument konstruktora
                // FormData(), jednak jest słabo wspierany w przeglądarkach.
                // Gdyby rozwiązanie było niewystarczające to można spróbować:
                // https://github.com/jenseng/formdata-submitter-polyfill
                const submitter = e.nativeEvent.submitter
                if (submitter && submitter instanceof HTMLButtonElement) {
                    formData.append(submitter.name, submitter.value)
                }

                const target =
                    submitter && (submitter instanceof HTMLButtonElement || submitter instanceof HTMLInputElement)
                        ? submitter
                        : form

                props.onSubmit(formData, target)
            }

            // Klasę .was-validated musimy skasować ponieważ będziemy
            // ponownie korzystać z walidacji po stronie serwera
            setValidated(false)
        }
    }

    return <BootsrapForm {...props} noValidate validated={validated} onSubmit={onSubmit} />
}

// Integracja formularza Bootstrap z akcjami React Routera
const ReacRouterForm: FC<Props> = ({ dispatchError, ...props }: Props): ReactElement => {
    const errors = useActionData() as FormErrors
    useEffect(() => {
        if (dispatchError) dispatchError(errors)
    }, [dispatchError, errors])

    const submit = useSubmit()
    const handleSubmit = (formData: FormData, target: SubmitTarget) => submit(target)

    return <SelfValidatingForm {...props} dispatchError={dispatchError} onSubmit={handleSubmit} />
}

export default Object.assign(ReacRouterForm, {
    Group: BootsrapForm.Group,
    Control: BootsrapForm.Control,
    Floating: BootsrapForm.Floating,
    Check: BootsrapForm.Check,
    Switch: BootsrapForm.Switch,
    Label: BootsrapForm.Label,
    Text: BootsrapForm.Text,
    Range: BootsrapForm.Range,
    Select: BootsrapForm.Select,
    FloatingLabel: BootsrapForm.FloatingLabel,
})
