import { FC, ReactElement, ReactNode } from 'react'
import { Link } from 'react-router-dom'
import LoginLinks from '../components/LoginLinks'
import MenuBottom from '../components/MenuBottom'
import { ReactComponent as RtmLogo } from '../assets/images/rtm_logo.svg'
import { ReactComponent as LogoFEPW } from '../assets/images/logo_ue_FEPW.svg'
import { ReactComponent as LogoRP } from '../assets/images/logo_ue_RP.svg'
import { ReactComponent as LogoEFRR } from '../assets/images/logo_ue_EFRR.svg'

type Props = {
    navbar?: ReactNode
    children?: ReactNode
}

const BaseLayout: FC<Props> = ({ navbar, children }: Props): ReactElement => {
    return (
        <>
            <header className="fixed-top">
                <div className="container-sm px-3 py-4 d-flex justify-content-between align-items-center flex-wrap flex-sm-nowrap">
                    <Link className="nav-item nav-link" to="/">
                        <RtmLogo className="rtm-logo" preserveAspectRatio="xMinYMid slice" />
                    </Link>
                    <LoginLinks />
                </div>
                <nav>{navbar}</nav>
            </header>

            {children}

            <div className="decorative-strip" />
            <footer>
                <div className="container-sm p-3 d-flex justify-content-between">
                    <Link className="nav-item nav-link" to="/">
                        <RtmLogo className="rtm-logo" preserveAspectRatio="xMinYMid slice" />
                    </Link>
                    <MenuBottom className="" />
                </div>
            </footer>

            <div className="ue-logos container-sm px-3 gap-4 d-flex justify-content-between align-items-center">
                <LogoFEPW
                    height="95"
                    width="139"
                    viewBox="53 0 139 95"
                    aria-label="Fundusze Europejskie Polska Wschodnia"
                />
                <LogoRP height="95" width="209" viewBox="18 0 209 95" aria-label="Rzeczpospolita Polska" />
                <LogoEFRR
                    height="95"
                    width="213"
                    viewBox="16 0 213 95"
                    aria-label="Europejski Fundusz Rozwoju Regionalnego"
                />
            </div>
        </>
    )
}

export default BaseLayout
