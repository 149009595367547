import { FC, useEffect } from 'react'
import { ActionFunction, redirect, useSubmit } from 'react-router-dom'
import AuthProvider from '../../lib/AuthProvider'

export const logoutAction: ActionFunction = async ({ request }) => {
    await AuthProvider.logout()

    return redirect('/')
}
const LogoutPage: FC = () => {
    const submit = useSubmit()

    useEffect(() => {
        submit(null, { method: 'post', action: '/logout' })
    }, [submit])

    return null
}

export default LogoutPage
