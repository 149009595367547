import { FC, ReactElement, Fragment, MouseEvent, ChangeEvent, createElement, useState, useEffect } from 'react'
import { Link, useLoaderData, useNavigate } from 'react-router-dom'
import Form, { useFormValues } from '../lib/Form'
import { AuthenticatedLoaderFunction } from '../lib/AuthProvider'
import api, { ApiError } from '../lib/Api'
import { CardInfo } from './account/PaymentCardsPage'
import { Passenger, PassengerTextView } from './account/PassengersPage'
import { TicketMedium } from './account/MobileDevicesPage'
import { TicketInfo } from '../lib/Interfaces'
import CardName from '../components/CardName'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCartShopping, faAngleLeft, faAngleRight, faMobileScreen } from '@fortawesome/free-solid-svg-icons'
import Datetime from 'react-datetime'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import Ticket from '../components/Ticket'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import { ReactComponent as BlikLogo } from '../assets/images/blik.svg'
import { ReactComponent as PayULogo } from '../assets/images/payu.svg'
import 'react-datetime/css/react-datetime.css'
import 'moment/locale/pl'

export interface LoaderData {
    zones: {
        id: string
        name: string
        description: string
    }[]
    discounts: {
        id: string
        name: string
        description: string
        rate: number
    }[]
    ticketsOffer: {
        [zoneId: string]: {
            [discountId: string]: TicketInfo[]
        }
    }
    userCards: CardInfo[]
    ticketMediums: TicketMedium[]
    passengers: Passenger[]
}

interface OrderItem {
    ticketType: TicketInfo
    quantity: number
    activationTime: Date | moment.Moment | undefined
}

type PaymentMethod = 'Wallet' | 'PayUBLIK' | 'PayU'

export const ticketsOfferLoader: AuthenticatedLoaderFunction = async ({ request, token }): Promise<LoaderData> => {
    const regionId = await api.get('/api/regions').then((r): string => r.data.regions[0].id)

    const data = await api
        .get(`/api/regions/${regionId}/tickets-offer?periodic=true&grouped=true`)
        .then((r): LoaderData => r.data)

    await api.get<{ cards: CardInfo[] }>('/api/cards').then(r => {
        data.userCards = r.data.cards.filter(card => !!card.hash)
    })

    await api.get<{ ticketMediums: TicketMedium[] }>('/api/ticket-mediums').then(r => {
        data.ticketMediums = r.data.ticketMediums
    })

    await api.get<{ passengers: Passenger[] }>('/api/passengers').then(r => {
        data.passengers = r.data.passengers
    })

    return data
}

const canAddNextTicket = (orderItems: OrderItem[]): boolean => {
    return orderItems.length < 10
}

const priceFormatter = new Intl.NumberFormat('pl-PL', {
    style: 'currency',
    currency: 'PLN',
})

const BuyTicketPage: FC = (): ReactElement => {
    const navigate = useNavigate()
    const { userCards, ticketMediums, passengers } = useLoaderData() as LoaderData
    const [isTicketSelectionVisible, setTicketSelectionVisibility] = useState(true)
    const [orderItems, setOrderItems] = useState([] as OrderItem[])
    const [idempotencyKey] = useState(uuidv4())
    const [errorMsg, setErrorMsg] = useState(null as string | null)

    const [values, onChange, setValues] = useFormValues({
        paymentMethod: 'PayU' as PaymentMethod,
        cardId: userCards.length > 0 ? userCards[0].id : '',
        ticketMediumId: '',
        passengerId: '',
    })

    const instanceOfCardInfo = (o: unknown): o is CardInfo => {
        return typeof o === 'object' && !!o && 'hash' in o
    }

    const ticketMedium: CardInfo | TicketMedium | undefined = values.cardId
        ? userCards.find(userCard => userCard.id === values.cardId)
        : ticketMediums.find(medium => medium.ticketMediumId === values.ticketMediumId)

    const passengersFiltered = instanceOfCardInfo(ticketMedium)
        ? passengers.filter(passenger => passenger.id === ticketMedium.passengerId)
        : passengers

    // Jeśli nie jest wybrany pasażer lub jest wybrany niewłaściwy (niedostępny)
    if (passengersFiltered.findIndex(passenger => values.passengerId === passenger.id?.toString()) === -1) {
        if (passengersFiltered.length === 1) {
            // Automatycznie zaznaczamy, jeśli jest tylko jeden
            setValues({ ...values, passengerId: passengersFiltered[0].id?.toString() ?? '' })
        } else if (values.passengerId !== '') {
            // Lub odznaczamy wybór, jeśli jest więcej możliwości
            setValues({ ...values, passengerId: '' })
        }
    }

    useEffect(() => {
        // Kasujemy komunikat o błędzie przy każdej zmianie w zamówieniu
        setErrorMsg(null)
    }, [values, orderItems])

    const summary = orderItems.reduce(
        (acc, item) => {
            acc.totalTickets += item.quantity
            acc.totalAmount += item.quantity * item.ticketType.price
            return acc
        },
        { totalTickets: 0, totalAmount: 0 }
    )

    const onSelectTicketType = (ticketType: TicketInfo, quantity: number): void => {
        setOrderItems([
            ...orderItems,
            {
                ticketType,
                quantity,
                activationTime: undefined,
            } as OrderItem,
        ])

        setTicketSelectionVisibility(false)
    }

    if (isTicketSelectionVisible) {
        return (
            <div className="p-5">
                <h1 className="h4 mb-3">Kup bilet</h1>

                <SelectTicketType onSelect={onSelectTicketType} />
            </div>
        )
    }

    const updateOrderItem =
        (idx: number) =>
        (orderItem: OrderItem): void => {
            const newOrderItems = [...orderItems]
            newOrderItems[idx] = orderItem
            setOrderItems(newOrderItems)
        }

    const configureTickets = createElement(
        Fragment,
        null,
        orderItems.map((orderItem, idx) => (
            <ConfigureTicket key={idx} orderItem={orderItem} onChange={updateOrderItem(idx)} />
        ))
    )

    const addNextTicketHandler = (e: MouseEvent<HTMLElement>) => {
        e.preventDefault()
        setTicketSelectionVisibility(true)
    }
    const addNextTicketLink = canAddNextTicket(orderItems) ? (
        <button className="btn btn-link p-0 mb-3 text-decoration-none text-muted" onClick={addNextTicketHandler}>
            + Dodaj kolejny bilet
        </button>
    ) : null

    const onTicketMediumChange =
        (item: CardInfo | TicketMedium) =>
        (e: ChangeEvent<HTMLInputElement>): void => {
            if (e.target.checked) {
                if (instanceOfCardInfo(item)) {
                    setValues({
                        ...values,
                        cardId: item.id,
                        ticketMediumId: '',
                        passengerId: item.passengerId.toString(),
                    })
                } else {
                    setValues({ ...values, cardId: '', ticketMediumId: item.ticketMediumId })
                }
            } else {
                if (instanceOfCardInfo(item) && values.cardId === item.id) {
                    setValues({ ...values, cardId: '' })
                } else if (!instanceOfCardInfo(item) && values.ticketMediumId === item.ticketMediumId) {
                    setValues({ ...values, ticketMediumId: '' })
                }
            }
        }

    const ticketMediumList =
        userCards.length + ticketMediums.length > 0 ? (
            createElement(
                'ul',
                {
                    className: 'list-group mb-4',
                },
                [
                    ...userCards.map((card, idx) => (
                        <li key={'card_' + card.id} className="list-group-item">
                            <Form.Check id={card.id}>
                                <Form.Check.Input
                                    type="radio"
                                    name="mediumId"
                                    value={'card_' + card.id}
                                    checked={values.cardId === card.id}
                                    onChange={onTicketMediumChange(card)}
                                />
                                <Form.Check.Label className="stretched-link">
                                    <CardName card={card} />
                                </Form.Check.Label>
                            </Form.Check>
                        </li>
                    )),
                    ...ticketMediums.map((ticketMedium, idx) => (
                        <li key={'app_' + ticketMedium.ticketMediumId} className="list-group-item">
                            <Form.Check id={ticketMedium.ticketMediumId}>
                                <Form.Check.Input
                                    type="radio"
                                    name="mediumId"
                                    value={'app_' + ticketMedium.ticketMediumId}
                                    checked={values.ticketMediumId === ticketMedium.ticketMediumId}
                                    onChange={onTicketMediumChange(ticketMedium)}
                                />
                                <Form.Check.Label className="stretched-link">
                                    <FontAwesomeIcon icon={faMobileScreen} fixedWidth={true} /> {ticketMedium.name}
                                </Form.Check.Label>
                            </Form.Check>
                        </li>
                    )),
                ]
            )
        ) : (
            <ul className="list-group mb-4">
                <li className="list-group-item">Brak nośników, na których można zakodować bilet.</li>
            </ul>
        )

    const newPassengerClick = async (e: MouseEvent<HTMLElement>) => {
        e.preventDefault()

        navigate('/your-account/passengers/add', { state: { successUrl: '/tickets/buy' } })
    }

    const passengersList = createElement(
        'ul',
        {
            className: 'list-group mb-4',
        },
        [
            ...passengers.map((passenger, idx) => (
                <li key={idx} className="list-group-item">
                    <Form.Check id={passenger.id?.toString()}>
                        <Form.Check.Input
                            type="radio"
                            name="passengerId"
                            value={passenger.id}
                            disabled={
                                instanceOfCardInfo(ticketMedium) ? ticketMedium.passengerId !== passenger.id : false
                            }
                            checked={values.passengerId === passenger.id?.toString()}
                            onChange={onChange}
                        />
                        <Form.Check.Label className="stretched-link">
                            {passenger.customName}
                            <div className="text-muted small">
                                <PassengerTextView passenger={passenger} separator={<span>, </span>} />
                            </div>
                        </Form.Check.Label>
                    </Form.Check>
                </li>
            )),
            !instanceOfCardInfo(ticketMedium) && (
                <li key="new-passenger" className="list-group-item">
                    <Button
                        variant="link"
                        className="p-0 text-decoration-none"
                        onClick={newPassengerClick}
                        disabled={instanceOfCardInfo(ticketMedium)}>
                        + dodaj nowego pasażera
                    </Button>
                </li>
            ),
        ]
    )

    let isValidForm = summary.totalTickets > 0 && !!ticketMedium && !!values.passengerId && !!values.paymentMethod
    orderItems.forEach(orderItem => {
        if (!orderItem.quantity) {
            return
        }

        if (!moment.isMoment(orderItem.activationTime)) {
            isValidForm = false
        }
    })

    const sendOrder = async (e: MouseEvent<HTMLElement>) => {
        e.preventDefault()

        if (!isValidForm || !ticketMedium) {
            return
        }

        try {
            const ticketMediumData = instanceOfCardInfo(ticketMedium)
                ? {
                      cardId: ticketMedium.id,
                      passengerId: ticketMedium.passengerId,
                  }
                : {
                      ticketMediumId: ticketMedium.ticketMediumId,
                      passengerId: +values.passengerId,
                  }

            const orderId = await api
                .post<{ orderId: string }>(
                    '/api/orders',
                    {
                        orderItems: orderItems.map(orderItem => ({
                            ticketTypeId: orderItem.ticketType.ticketTypeId,
                            quantity: orderItem.quantity,
                            activationTimestamp: moment.isMoment(orderItem.activationTime)
                                ? orderItem.activationTime.format()
                                : undefined,
                        })),
                        ...ticketMediumData,
                        paymentMethod: values.paymentMethod,
                    },
                    {
                        headers: {
                            'Idempotency-Key': idempotencyKey,
                        },
                    }
                )
                .then(r => r.data.orderId)

            if (values.paymentMethod !== 'Wallet') {
                type PaymentResponse = {
                    paymentId: string
                    payu: {
                        orderId: string
                        redirectUri: string
                        status: {
                            statusCode: string
                        }
                    }
                }
                const payment = await api
                    .post<PaymentResponse>(
                        '/api/payments',
                        {
                            type: 'Order',
                            orderId,
                            method: values.paymentMethod,
                            continueUrl: new URL(`/tickets/payment/${orderId}`, document.baseURI).href,
                        },
                        {
                            headers: {
                                'Idempotency-Key': idempotencyKey,
                            },
                        }
                    )
                    .then(r => r.data)

                if (payment?.payu?.status?.statusCode !== 'SUCCESS') {
                    throw new Error('Nie udało się zarejestrować płatności PayU.')
                }
                if (!payment.payu.redirectUri) {
                    throw new Error('Nie można ustalić przekierowania do płatności.')
                }

                // Przekierowujemy do strony PayU kasując z historii bieżącą stronę
                window.location.replace(payment.payu.redirectUri)
            }
        } catch (error) {
            if (error instanceof ApiError) {
                setErrorMsg(`Wystąpił błąd podczas zapisu zamówienia. Odpowiedź serwera: ${error.message}`)
            } else if (error instanceof Error) {
                setErrorMsg(`Wystąpił błąd podczas zapisu zamówienia. ${error.message}`)
            } else {
                setErrorMsg('Wystąpił błąd podczas zapisu zamówienia. Spróbuj ponownie później.')
            }
        }
    }

    return (
        <div className="p-5">
            <h1 className="h4 mb-3">Kup bilet</h1>

            <div className="container-fluid">
                <div className="row g-2 mb-2">
                    <div className="col-4 d-none d-md-block"></div>
                    <div className="col-4 d-none d-md-block text-center">Data aktywacji:</div>
                    <div className="col-2 d-none d-md-block text-center">Cena:</div>
                    <div className="col-2 d-none d-md-block text-center align-middle">Ilość:</div>
                </div>

                {configureTickets}
                {addNextTicketLink}

                <div className="mb-4">
                    Liczba biletów: {summary.totalTickets}
                    <br />
                    <strong>Do zapłaty</strong> (w tym VAT): {priceFormatter.format(summary.totalAmount / 100.0)}
                </div>
            </div>

            <h2 className="h5">Wybierz nośnik biletu:</h2>
            {ticketMediumList}

            <h2 className="h5">Wybierz pasażera:</h2>
            {passengersList}

            <h2 className="h5">Wybierz formę płatności:</h2>
            <ul className="list-group list-group-horizontal-sm mb-4">
                <li className="list-group-item">
                    <Form.Check id="PayU">
                        <Form.Check.Input
                            type="radio"
                            name="paymentMethod"
                            value="PayU"
                            checked={values.paymentMethod === 'PayU'}
                            onChange={onChange}
                        />
                        <Form.Check.Label className="stretched-link">
                            <PayULogo preserveAspectRatio="xMinYMid meet" className="w-100" height="30" />
                        </Form.Check.Label>
                    </Form.Check>
                </li>
                <li className="list-group-item">
                    <Form.Check id="PayUBLIK">
                        <Form.Check.Input
                            type="radio"
                            name="paymentMethod"
                            value="PayUBLIK"
                            checked={values.paymentMethod === 'PayUBLIK'}
                            onChange={onChange}
                        />
                        <Form.Check.Label className="stretched-link">
                            <BlikLogo preserveAspectRatio="xMinYMid meet" className="w-100" height="30" />
                        </Form.Check.Label>
                    </Form.Check>
                </li>
                {/*<li className="list-group-item">
                    <Form.Check id="Wallet">
                        <Form.Check.Input
                            type="radio"
                            name="paymentMethod"
                            value="Wallet"
                            checked={values.paymentMethod === 'Wallet'}
                            disabled={true}
                            onChange={onChange}
                        />
                        <Form.Check.Label className="stretched-link">Portfel</Form.Check.Label>
                    </Form.Check>
                </li>*/}
            </ul>

            {errorMsg && (
                <Alert variant="danger" className="text-center">
                    {errorMsg}
                </Alert>
            )}

            <div className="d-block text-end mt-3">
                <Link className="btn btn-light" role="button" to="/tickets">
                    <FontAwesomeIcon icon={faAngleLeft} fixedWidth={true} /> Anuluj zamówienie
                </Link>{' '}
                <Button disabled={!isValidForm} onClick={sendOrder} variant="primary" type="submit">
                    Kupuję i płacę <FontAwesomeIcon icon={faAngleRight} fixedWidth={true} />
                </Button>
            </div>
        </div>
    )
}

interface SelectTicketTypeParams {
    onSelect: (ticketType: TicketInfo, quantity: number) => void
}

const SelectTicketType: FC<SelectTicketTypeParams> = ({ onSelect }): ReactElement => {
    const { zones, discounts, ticketsOffer } = useLoaderData() as LoaderData
    const [values, onChange] = useFormValues({
        zone_id: zones[0].id,
        discount_id: discounts[0].id,
    })

    const buttonClickHandler = (ticket: TicketInfo) => (e: MouseEvent<HTMLElement>) => {
        e.preventDefault()

        onSelect(ticket, 1)
    }

    const zoneSelect = createElement(
        'div',
        { className: 'btn-group mb-3 w-100', role: 'group' },
        zones.map(zone => (
            <Fragment key={zone.id}>
                <input
                    type="radio"
                    className="btn-check"
                    name="zone_id"
                    id={`zone_${zone.id}`}
                    value={zone.id}
                    autoComplete="off"
                    checked={values.zone_id === zone.id}
                    onChange={onChange}
                />
                <label htmlFor={`zone_${zone.id}`} className="btn btn-outline-primary">
                    <strong className="d-block">{zone.name}</strong>
                    <div className="d-none d-md-block">{zone.description}</div>
                </label>
            </Fragment>
        ))
    )

    const discountSelect = createElement(
        'div',
        { className: 'btn-group mb-5 w-100', role: 'group' },
        discounts.map(discount => (
            <Fragment key={discount.id}>
                <input
                    type="radio"
                    className="btn-check"
                    name="discount_id"
                    id={`discount_${discount.id}`}
                    value={discount.id}
                    autoComplete="off"
                    checked={values.discount_id === discount.id}
                    onChange={onChange}
                />
                <label htmlFor={`discount_${discount.id}`} className="btn btn-outline-primary">
                    <strong className="d-block">{discount.name}</strong>
                    <div className="d-none d-md-block">{discount.description}</div>
                </label>
            </Fragment>
        ))
    )

    const ticketListView = createElement(
        Row,
        { xs: 1, sm: 2, md: 3, lg: 4, className: 'g-4' },
        ticketsOffer[values.zone_id][values.discount_id].map((ticket: TicketInfo) => (
            <Col key={ticket.ticketTypeId}>
                <Ticket ticket={ticket}>
                    <Button variant="primary" className="stretched-link float-end" onClick={buttonClickHandler(ticket)}>
                        <FontAwesomeIcon icon={faCartShopping} /> Kup
                    </Button>
                </Ticket>
            </Col>
        ))
    )

    return (
        <>
            {zoneSelect}

            {discountSelect}

            {ticketListView}
        </>
    )
}

interface ConfigureTicketParams {
    orderItem: OrderItem
    onChange: (orderItem: OrderItem) => void
}

const ConfigureTicket: FC<ConfigureTicketParams> = ({ orderItem, onChange }): ReactElement => {
    const [activationTimeValue, setActivationTimeValue] = useState(
        orderItem.activationTime as Date | moment.Moment | string
    )
    const setQuantity = (quantity: number) => (e: MouseEvent<HTMLElement>) => {
        e.preventDefault()

        const newOrderItem = Object.assign({}, orderItem)
        newOrderItem.quantity = quantity

        onChange(newOrderItem)
    }

    const setActivationTime = (activationTime: moment.Moment | string) => {
        setActivationTimeValue(activationTime)

        const newOrderItem = Object.assign({}, orderItem)

        // Jeśli jest wybrana poprawna data to zawsze zwracany jest obiekt typu Moment
        if (moment.isMoment(activationTime)) {
            if (orderItem.ticketType.activationTimeSelect === 'Month') {
                activationTime.startOf('month')
            } else if (orderItem.ticketType.activationTimeSelect === 'Date') {
                activationTime.startOf('day')
            } else if (orderItem.ticketType.activationTimeSelect === 'DateTime') {
                activationTime.startOf('minute')
            }

            newOrderItem.activationTime = activationTime
        } else {
            newOrderItem.activationTime = undefined
        }

        onChange(newOrderItem)
    }

    let activationTimeSelect = null
    const currentTimestamp = moment()
    const dateValidator =
        (minValue: moment.Moment) =>
        (value: moment.Moment): boolean => {
            return value.isSameOrAfter(minValue) && value.isSameOrBefore(moment(minValue).add(3, 'months'))
        }
    if (orderItem.ticketType.activationTimeSelect === 'Month') {
        const minValue = moment(currentTimestamp).startOf('month')
        if (currentTimestamp.date() > 15) {
            minValue.add(1, 'months')
        }

        activationTimeSelect = (
            <Datetime
                locale="pl"
                dateFormat="YYYY-MM"
                timeFormat={false}
                className="d-inline-block"
                inputProps={{ placeholder: 'Wybierz miesiąc', className: 'form-control' }}
                closeOnSelect={true}
                isValidDate={dateValidator(minValue)}
                value={activationTimeValue}
                onChange={setActivationTime}
            />
        )
    } else if (orderItem.ticketType.activationTimeSelect === 'Date') {
        const minValue = moment(currentTimestamp).startOf('day')

        activationTimeSelect = (
            <Datetime
                locale="pl"
                dateFormat="YYYY-MM-DD"
                timeFormat={false}
                className="d-inline-block"
                inputProps={{ placeholder: 'Wybierz datę', className: 'form-control' }}
                closeOnSelect={true}
                isValidDate={dateValidator(minValue)}
                value={activationTimeValue}
                onChange={setActivationTime}
            />
        )
    } else if (orderItem.ticketType.activationTimeSelect === 'DateTime') {
        activationTimeSelect = (
            <Datetime
                locale="pl"
                dateFormat="YYYY-MM-DD"
                timeFormat="HH:mm"
                className="d-inline-block"
                inputProps={{ placeholder: 'Wybierz datę/godzinę', className: 'form-control' }}
                closeOnSelect={true}
                isValidDate={dateValidator(currentTimestamp)}
                value={activationTimeValue}
                onChange={setActivationTime}
            />
        )
    }

    return (
        <div className="row g-2 mb-4">
            <div className="col-12 col-md-4">
                <strong>{orderItem.ticketType.name}</strong>
                <br />
                {orderItem.ticketType.discountDetails}, {orderItem.ticketType.zones}
            </div>
            <div className="col-12 col-md-8">
                <div className="row g-2">
                    <div className="col-4 col-sm-6 d-md-none text-center">Data aktywacji:</div>
                    <div className="col-4 col-sm-3 d-md-none text-center">Cena:</div>
                    <div className="col-4 col-sm-3 d-md-none text-center align-middle">Ilość:</div>

                    <div className="col-4 col-sm-6 text-center">{activationTimeSelect}</div>
                    <div className="col-4 col-sm-3 text-center">
                        {priceFormatter.format(orderItem.ticketType.price / 100.0)}
                    </div>
                    <div className="col-4 col-sm-3 text-center align-middle">
                        <Button
                            variant="primary"
                            size="sm"
                            onClick={setQuantity(orderItem.quantity - 1)}
                            disabled={orderItem.quantity < 1}>
                            -
                        </Button>
                        <div className="d-inline-block mx-2" style={{ width: '1.5em' }}>
                            {orderItem.quantity}
                        </div>
                        <Button
                            variant="primary"
                            size="sm"
                            onClick={setQuantity(orderItem.quantity + 1)}
                            disabled={orderItem.quantity >= 20}>
                            +
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BuyTicketPage
