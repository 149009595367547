import { FC, ReactElement, PropsWithChildren, MouseEvent, useCallback } from 'react'
import { Link, useSubmit, useNavigation } from 'react-router-dom'
import Form, { useFormValues, useFormErrors } from '../lib/Form'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import { ReactComponent as EmailIcon } from '../assets/images/email.svg'

interface VeryficationCodeFormParams {
    context: 'create-account' | 'password-reset'
    email: string
    verificationId: string
}

const VeryficationCodeForm: FC<PropsWithChildren<VeryficationCodeFormParams>> = ({
    context,
    email,
    verificationId,
    children,
}): ReactElement => {
    const [errors, setErrors] = useFormErrors()
    const [values, onChange] = useFormValues({
        verification_code: '',
    })

    let className: string
    let actionUrl: string
    switch (context) {
        case 'create-account':
            className = 'create-account'
            actionUrl = '/create-account'
            break
        case 'password-reset':
            className = 'reset-password'
            actionUrl = '/password-reset'
            break
    }

    const submit = useSubmit()
    const sendAgain = useCallback(
        (e: MouseEvent<HTMLElement>) => {
            e.preventDefault()

            submit(
                {
                    email,
                },
                {
                    action: actionUrl,
                    method: 'post',
                    encType: 'application/x-www-form-urlencoded',
                }
            )
        },
        [submit, actionUrl, email]
    )

    let sendAgainText = (
        <p className="text-muted text-center mt-4 small">
            Kod nie dotarł?{' '}
            <Link className="text-decoration-none" to={actionUrl} onClick={sendAgain}>
                Wyślij ponownie
            </Link>
        </p>
    )
    const navigation = useNavigation()
    if (navigation.state === 'submitting') {
        sendAgainText = (
            <p className="text-center mt-4 small">
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> Wysyłanie...
            </p>
        )
    }

    return (
        <Form className={className} action={`${actionUrl}/${verificationId}`} method="post" dispatchError={setErrors}>
            <p className="my-4 text-center">
                <EmailIcon className="d-block mx-auto mb-3" preserveAspectRatio="xMidYMid meet" height="60" />
                {children}
            </p>

            <Form.Group className="form-group" controlId="verification_code">
                <Form.Control
                    type="text"
                    name="verification_code"
                    placeholder="Kod weryfikacyjny"
                    value={values.verification_code}
                    onChange={onChange}
                    required
                    autoFocus
                    isInvalid={!!errors.message}
                />
                <Form.Control.Feedback type="invalid">{errors.message}</Form.Control.Feedback>
            </Form.Group>

            <div className="d-grid">
                <Button variant="primary" type="submit">
                    Dalej
                </Button>
            </div>

            {sendAgainText}
        </Form>
    )
}

export default VeryficationCodeForm
