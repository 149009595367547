import { FC, ReactElement } from 'react'
import { Link, LoaderFunction, redirect, useLoaderData } from 'react-router-dom'
import api from '../lib/Api'
import Table from 'react-bootstrap/Table'
import Pagination from '../components/Pagination'

const limitPerPage = 40

interface JourneyInfo {
    journeyDate: string
    amount: number
}

interface PaginationInfo {
    currentPage: number
    totalPages: number
    totalCount: number
    limit: number
    offset: number
}

export const journeysLoader: LoaderFunction = async ({ params: { page } }) => {
    const currentPage = page ? parseInt(page) : 1
    if (currentPage < 1) {
        return redirect('../journey-history')
    }
    const offset = (currentPage - 1) * limitPerPage
    const result = await api.get(`/api/journeys?offset=${offset}&limit=${limitPerPage}`).then(r => r.data)
    const paginationInfo: PaginationInfo = {
        currentPage,
        totalPages: Math.ceil(result.totalCount / limitPerPage),
        totalCount: result.totalCount,
        limit: limitPerPage,
        offset,
    }
    return {
        pagination: paginationInfo,
        journeys: result.data,
    }
}

const JourneysPage: FC = (): ReactElement => {
    const { journeys, pagination } = useLoaderData() as {
        journeys: JourneyInfo[]
        pagination: PaginationInfo
    }

    const formatter = new Intl.NumberFormat('pl-PL', {
        style: 'currency',
        currency: 'PLN',
    })

    return (
        <>
            <h1 className="h4 mb-3">Moje przejazdy</h1>

            {pagination.totalCount ? (
                <>
                    <Table responsive hover>
                        <thead>
                            <tr>
                                <th scope="col">Data</th>
                                <th className="text-right" scope="col">
                                    Kwota
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {journeys.map((journey, idx) => (
                                <tr key={idx}>
                                    <td>{new Date(journey.journeyDate).toLocaleDateString()}</td>
                                    <td className="text-right">{formatter.format(journey.amount / 100.0)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>

                    <nav aria-label="Stronicowanie">
                        <Pagination
                            currentPage={pagination.currentPage}
                            totalPages={pagination.totalPages}
                            href={page => (page > 1 ? `../journey-history/${page}` : '../journey-history')}
                            className="justify-content-center mt-3"
                        />
                    </nav>
                </>
            ) : (
                <>
                    <div className="text-center text-muted small">Brak zarejestrowanych przejazdów</div>
                    <p className="text-center mt-5">
                        Niestety nie posiadamy zarejestrowanych Twoich przejazdów. Upewnij się, że masz dodaną właściwą{' '}
                        <Link to="/your-account/cards">kartę</Link> w swoim profilu.
                    </p>
                </>
            )}
        </>
    )
}

export default JourneysPage
