import { FC, ReactElement } from 'react'
import { useRouteError, isRouteErrorResponse } from 'react-router-dom'

const ErrorPage: FC = (): ReactElement => {
    const error = useRouteError()

    let message = 'Wystąpił błąd'
    if (isRouteErrorResponse(error)) {
        switch (error.status) {
            case 404:
                message = 'Nie znaleziono szukanej strony'
                break
            case 401:
                message = 'Brak wymaganych uprawnień do przeglądania strony'
                break
            case 503:
                message = 'Serwer jest chwilowo niedostępny. Spróbuj ponownie później.'
                break
        }
        console.error(error.statusText)
    } else if (error instanceof Error) {
        console.error(error.message)
    } else if (typeof error === 'string') {
        console.error(error)
    }

    return <h1 className="h4 mb-3 text-center">{message}</h1>
}

export default ErrorPage
