import { FC, ReactElement, PropsWithChildren, useContext } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import UserContext from '../contexts/user'

const RequireAuth: FC<PropsWithChildren> = ({ children }): ReactElement => {
    const { user } = useContext(UserContext)

    // Adres powrotu po zalogowaniu
    const location = useLocation()
    const singInUrl = '/sign-in?' + new URLSearchParams({ returnUrl: location.pathname }).toString()

    if (user) {
        return <>{children}</>
    } else {
        return <Navigate to={singInUrl} />
    }
}

export default RequireAuth
