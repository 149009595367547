import { FC, ReactElement } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import Alert from 'react-bootstrap/Alert'

const PaymentStatusPage: FC = (): ReactElement => {
    const [urlParams] = useSearchParams()

    if (urlParams.has('error')) {
        return (
            <div className="narrow-layout pt-4">
                <Alert variant="danger" className="text-center">
                    <h1 className="h4">Płatność odrzucona</h1>
                </Alert>
                <p className="text-muted small mb-2">
                    Twoje zamówienie nie zostało opłacone. Aby dowiedzieć się więcej, sprawdź skrzynkę e-mail.
                </p>
                <Link className="text-decoration-none" to="/tickets">
                    Powrót
                </Link>
            </div>
        )
    }

    return (
        <div className="narrow-layout pt-4">
            <Alert variant="info" className="text-center">
                <h1 className="h4">Dziękujemy za wpłatę</h1>
            </Alert>
            <p className="text-muted small mb-2">
                Twoje zamówienie zostało opłacone i zostanie zrealizowane, gdy tylko otrzymamy potwierdzenie od
                operatora płatności - PayU. Zazwyczaj nie trwa to dłużej niż kilka minut, jednak w przypadku niektórych
                form płatności okres ten może być dłuższy.
            </p>
            <Link className="text-decoration-none" to="/tickets">
                Powrót
            </Link>
        </div>
    )
}

export default PaymentStatusPage
