import { FC, ReactElement } from 'react'
import { Link } from 'react-router-dom'

interface Props {
    className?: string
}

const MenuBottom: FC<Props> = ({ className } = { className: '' }): ReactElement => {
    return (
        <ul className={'' + className}>
            <li>
                <Link to="/terms-of-service">Regulamin</Link>
            </li>
            <li>
                <Link to="/privacy-policy">Polityka prywatności</Link>
            </li>
        </ul>
    )
}

export default MenuBottom
