import { FC, ReactElement } from 'react'
import { CardInfo } from '../pages/account/PaymentCardsPage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCreditCard } from '@fortawesome/free-solid-svg-icons'
import { ReactComponent as VisaLogo } from '../assets/images/cc_visa_logo.svg'
import { ReactComponent as MastercardLogo } from '../assets/images/cc_mastercard_logo.svg'

interface Props {
    card: CardInfo
}

const CardName: FC<Props> = ({ card }: Props): ReactElement => {
    let icon = <FontAwesomeIcon icon={faCreditCard} fixedWidth={true} />

    if (card.cardType && card.cardType.match(/visa/i)) {
        icon = <VisaLogo preserveAspectRatio="xMinYMid meet" width="1.25em" height="1.25em" />
    } else if (card.cardType && card.cardType.match(/master\s*card/i)) {
        icon = <MastercardLogo preserveAspectRatio="xMinYMid meet" width="1.25em" height="1.25em" />
    }

    return (
        <>
            {icon} Karta {card.maskedPan}
        </>
    )
}

export default CardName
