import React from 'react'
import ReactDOM from 'react-dom/client'
import './assets/scss/custom_bootstrap.scss'
import './assets/scss/main.scss'
import 'bootstrap/dist/js/bootstrap.bundle.min'
import AppRoutes from './AppRoutes'
import reportWebVitals from './reportWebVitals'

const rootNode = document.createElement('div') as HTMLElement
rootNode.classList.add('min-vh-100', 'd-flex', 'flex-column')
ReactDOM.createRoot(rootNode).render(
    <React.StrictMode>
        <AppRoutes />
    </React.StrictMode>
)
document.body.appendChild(rootNode)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
