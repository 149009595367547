export interface TicketInfo {
    ticketTypeId: string
    name: string
    zones: string
    info: string
    type: 'OneTime' | 'Time' | 'ShortTerm' | 'Monthly' | 'Group'
    activationTimeSelect: 'None' | 'Month' | 'Date' | 'DateTime'
    discount: boolean
    discountDetails: string
    validity: string
    price: number
    inSaleFrom: string
    inSaleTo: string
}

export interface ActivatedTicketInfo extends TicketInfo {
    regionId?: string
    passengerId?: number
    activeFrom?: string
    activeTo?: string
    expirationTime?: string
    controlDetails?: string
    activationParameters?: {
        showTextField?: boolean
        textFieldLabel?: string
        allowedValues?: boolean
        showQRCodeScanner?: boolean
        presentQRCodeValue?: boolean
    }
}

export const isActivatedTicket = (ticket: TicketInfo): ticket is ActivatedTicketInfo => {
    return 'activeFrom' in ticket && (ticket as ActivatedTicketInfo).activeFrom != null
}
