import axios from 'axios'
import AuthProvider from './AuthProvider'

export const OAUTH_URL = '/api/oauth/token'

interface ApiErrorResponse {
    code: string
    message: string
    details?: string
}

export class ApiError extends Error {
    constructor(
        message: string,
        readonly code: string,
        readonly details?: string
    ) {
        super(message)
        Object.setPrototypeOf(this, ApiError.prototype)
    }
}

export class ApiValidationError extends ApiError {
    constructor(
        message: string,
        code: string,
        readonly fieldName: string,
        details?: string
    ) {
        super(message, code, details)
        Object.setPrototypeOf(this, ApiValidationError.prototype)
    }
}

const api = axios.create({
    //baseURL: 'https://example.com/api/',
    headers: {
        //'X-Custom-Header': 'value',
    },
})

api.interceptors.request.use(async config => {
    try {
        if (config.method === 'post' && config.url === OAUTH_URL) {
            // Zabezpieczenie przed rekurencyjnym zapętleniem metody getToken()
        } else if (config.headers.Authorization === undefined) {
            // Automatycznie dołączamy token JWT dla zalogowanego użytkownika
            const token = await AuthProvider.getToken()
            config.headers.Authorization = `Bearer ${token.hash}`
        }
    } catch (error) {}

    if (config.timeout === undefined) {
        config.timeout = 5000 // Timeout domyślnie 5 sekund
    }

    return config
})

api.interceptors.response.use(
    response => response,
    error => {
        if (axios.isAxiosError<ApiErrorResponse>(error)) {
            const response = error.response?.data
            if (response) {
                if (response.code === 'validation_error') {
                    const [, fieldName] = response.message.match(/^Invalid value specified for (.*)$/) ?? ['', '']
                    if (fieldName) {
                        return Promise.reject(
                            new ApiValidationError(response.message, response.code, fieldName, response.details)
                        )
                    }
                }

                return Promise.reject(new ApiError(response.message, response.code, response.details))
            }
        }
        return Promise.reject(error)
    }
)

export default api
