import { FC, ReactElement } from 'react'
import { Link, Outlet, useOutlet } from 'react-router-dom'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faAngleRight,
    faAngleLeft,
    faCreditCard,
    faMobileScreen,
    faGear,
    faSignOutAlt,
} from '@fortawesome/free-solid-svg-icons'
import { faIdCard } from '@fortawesome/free-regular-svg-icons'

type LinksProps = {
    url: string
    name: string
    icon: IconProp
}

const MenuLinkTemplate: FC<LinksProps> = ({ url, name, icon }) => {
    return (
        <Link className="nav-link d-flex align-items-center text-dark" to={url}>
            <FontAwesomeIcon className="" icon={icon} fixedWidth={true} />
            <span className="px-2 flex-grow-1">{name}</span>
            <FontAwesomeIcon className="d-sm-none" icon={faAngleRight} fixedWidth={true} />
        </Link>
    )
}

const AccountPage: FC = (): ReactElement => {
    const menu = (
        <ul className="nav flex-column">
            <li key="passengers" className="nav-item">
                <MenuLinkTemplate url="./passengers" name="Dane pasażerów" icon={faIdCard} />
            </li>
            <li key="cards" className="nav-item">
                <MenuLinkTemplate url="./cards" name="Karty płatnicze" icon={faCreditCard} />
            </li>
            <li key="devices" className="nav-item">
                <MenuLinkTemplate url="./devices" name="Urządzenia mobilne" icon={faMobileScreen} />
            </li>
            <li key="settings" className="nav-item">
                <MenuLinkTemplate url="./settings" name="Ustawienia konta" icon={faGear} />
            </li>
            <li key="logout" className="nav-item">
                <MenuLinkTemplate url="/logout" name="Wyloguj się" icon={faSignOutAlt} />
            </li>
        </ul>
    )

    const outlet = useOutlet()
    if (!outlet) {
        // Jeśli nie mamy wybranej pozycji menu, to wyświetlamy menu w lewej kolumnie
        // oraz podsumowanie w prawej jeśli jest wystarczająco miejsca
        return (
            <div className="flex-grow-1 row">
                <div className="col col-sm-5 col-md-4">
                    <h1 className="h5 pb-2 mb-2 border-bottom">Twoje konto</h1>
                    {menu}
                </div>
                <div className="d-none d-sm-block col-sm-7 col-md-6 col-lg-5">Informacja o koncie</div>
            </div>
        )
    } else {
        // Jeśli mamy wybraną pozycję menu, to menu pokazujemy opcjonalnie - jeśli
        // jest wystarczająco miejsca. Gdy je ukrywamy to wyświetlamy przycisk powrót.
        return (
            <div className="flex-grow-1 row p-3">
                <div className="d-none d-sm-block col-sm-5 col-md-4">
                    <h1 className="h5 pb-2 mb-2 border-bottom">Twoje konto</h1>
                    {menu}
                </div>
                <div className="col col-sm-7 col-md-6 col-lg-5">
                    <div className="d-block d-sm-none mb-2">
                        <Link className="btn btn-secondary" to=".">
                            <FontAwesomeIcon className="d-sm-none" icon={faAngleLeft} fixedWidth={true} /> Powrót
                        </Link>
                    </div>

                    <Outlet />
                </div>
            </div>
        )
    }
}

export default AccountPage
